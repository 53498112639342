import { isEqual } from "lodash";

export default {
  name: "ClientSales",
  props: {
    data: {
      type: Object,
      required: true,
    },
    editMode: {
      required: false,
      type: Boolean,
      default: false,
    },
    isSaving: {
      required: false,
      type: Boolean,
    },
    isEditing: {
      required: false,
      type: Boolean,
    },
    clientId: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      salesAssignmentCopy: null,
      rules: {
        required: (val) => {
          if (typeof val === "object")
            return (val.id && val.id !== null) || "This field is required";

          return (
            (val !== null && val.length !== 0) || "This field is required."
          );
        },
        validateEmail: (val) =>
          this.validateEmail(val) || "Invalid email format.",
      },
    };
  },
  computed: {
    __SalesAssignment: {
      get() {
        return this.data;
      },
      set(value) {
        this.$emit("update:data", value);
      },
    },
    __IsEditing: {
      get() {
        return this.isEditing;
      },
      set(value) {
        this.$emit("update:isEditing", value);
      },
    },
    fieldDisabled() {
      return (!this.isEditing && this.editMode) || this.isSaving;
    },
  },
  methods: {
    async doSave() {
      const validate = await this.$refs.form.validateAsync();

      if (isEqual(this.salesAssignmentCopy, this.__SalesAssignment)) {
        this.showNotifyMessage({
          message: `No changes were made.`,
          type: "danger",
        });
        return;
      }
      if (validate) this.$emit("doSave");
    },
    doEdit() {
      this.__IsEditing = true;
    },
    doCancel() {
      this.$emit("doCancel");
    },

    setSalesCopyObject(sales = null) {
      this.salesAssignmentCopy = JSON.parse(JSON.stringify(sales ? sales : this.data));
    }
  },

  mounted() {
    this.setSalesCopyObject();
  },
};
