import isEqual from "lodash/isEqual";

import ClientAccountsActions from "modules/actions/client-accounts-actions";

export default {
  name: "ClientContactModal",
  props: {
    existingContact: {
      required: false,
      type: Object,
    },
    clientId: {
      type: String,
    },
    editMode: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      actions: new ClientAccountsActions(),
      isLoading: false,
      isDefaultInitially: false,
      emailTypes: [],
      contactTypes: [],
      prompt: {
        visibility: false,
        message: "",
        action: "",
      },
      rules: {
        required: (val) => {
          if (typeof val === "object")
            return (
              (val !== null && val.id && val.id !== null) ||
              "This field is required"
            );

          return (
            (val !== null && val.length !== 0) || "This field is required."
          );
        },
        validateEmailType: (val, email) => {
          return email.length > 0
            ? (val !== null && val.length > 0) || "This field is required."
            : true;
        },
        validateEmail: (val) =>
          this.validateEmail(val) || "Invalid email format.",
      },
      contactInformation: {
        id: null,
        isDefault: true,
        firstName: "",
        middleName: "",
        lastName: "",
        nameTitle: "",
        contactType: {
          id: null,
          label: "",
        },
        mainPhone: {
          id: null,
          phone: "",
          areaCode: "",
        },
        mainFax: {
          id: null,
          phone: "",
          areaCode: "",
        },
        contactEmail: {
          id: null,
          email: "",
          emailTypeId: null,
        },
      },
    };
  },
  computed: {
    disableField() {
      return this.isLoading;
    },
    headerLabel() {
      if (!this.editMode) return "Contact Information Details";

      return (this.existingContact ? "Edit" : "Add") + " Contact Information";
    },
    btnLabel() {
      return this.existingContact ? "Save" : "Add";
    },
  },
  methods: {
    async getEmailTypes() {
      try {
        if (!this.emailTypes.length) {
          this.emailTypes = [
            { label: "Fetching email types...", disabled: true },
          ];

          const result = await this.actions.getEmailTypes();

          if (result) {
            this.emailTypes = result.map((item) => {
              return {
                id: item.id,
                label: item.label,
              };
            });
          } else {
            this.showNotifyMessage({
              type: "danger",
              message: "Unable to fetch data.",
            });
          }
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Unable to fetch data.",
        });
      }
    },

    async getContactTypes() {
      if (this.contactTypes.length) return;

      try {
        this.contactTypes = [
          { label: "Fetching contact types...", disabled: true },
        ];

        const result = await this.actions.getContactTypes({
          order: {
            by: "orderByLabel",
            order: "ASC",
          },
        });

        if (result) {
          this.contactTypes = result.map((item) => {
            return {
              id: item.id,
              label: item.label,
            };
          });
        } else {
          this.showNotifyMessage({
            type: "danger",
            message: "Something went wrong while fetching contact types.",
          });
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Something went wrong while fetching contact types.",
        });
      }
    },

    async doSave() {
      try {
        this.isLoading = true;

        const validate = await this.validateForm();

        if (validate) {
          const {
            isDefault,
            firstName,
            middleName,
            lastName,
            nameTitle,
            mainPhone,
            mainFax,
            contactEmail,
          } = this.contactInformation;

          if (this.contactInformation.id) {
            let collectionQueries = [];

            if (mainFax.id) {
              collectionQueries.push({
                updateFax: {
                  phoneId: {
                    type: "UUID!",
                    value: mainFax.id,
                  },
                  phone: {
                    type: "String",
                    value: this.sanitizePhoneString(mainFax.phone),
                  },
                  areaCode: {
                    type: "String",
                    value: this.sanitizePhoneString(mainFax.areaCode),
                  },
                },
              });
            } else {
              const createMainFax = await this.actions.createPhone({
                phone: {
                  type: "String!",
                  value: this.sanitizePhoneString(mainFax.phone),
                  unincludeToFields: true,
                },
                areaCode: {
                  type: "String",
                  value: this.sanitizePhoneString(mainFax.areaCode),
                  unincludeToFields: true,
                },
              });

              if (createMainFax) mainFax.id = createMainFax.id;

              collectionQueries.push({
                setMainFax: {
                  phoneId: {
                    type: "UUID!",
                    value: mainFax.id,
                  },
                },
              });
            }

            if (mainPhone.id) {
              collectionQueries.push({
                updatePhone: {
                  phoneId: {
                    type: "UUID!",
                    value: mainPhone.id,
                  },
                  phone: {
                    type: "String",
                    value: this.sanitizePhoneString(mainPhone.phone),
                  },
                  areaCode: {
                    type: "String",
                    value: this.sanitizePhoneString(mainPhone.areaCode),
                  },
                },
              });
            } else {
              const createMainPhone = await this.actions.createPhone({
                phone: {
                  type: "String!",
                  value: this.sanitizePhoneString(mainPhone.phone),
                  unincludeToFields: true,
                },
                areaCode: {
                  type: "String",
                  value: this.sanitizePhoneString(mainPhone.areaCode),
                  unincludeToFields: true,
                },
              });
              if (createMainPhone) mainPhone.id = createMainPhone.id;

              collectionQueries.push({
                setMainPhone: {
                  phoneId: {
                    type: "UUID!",
                    value: mainPhone.id,
                  },
                },
              });
            }

            await this.actions.updateContactInformation({
              variables: {
                contactInformationId: {
                  type: "UUID!",
                  value: this.contactInformation.id,
                  unincludeToFields: true,
                },
              },
              queries: {
                // temporarily disable
                // setContactType: {
                //   contactTypeId: {
                //     type: "UUID!",
                //     value: contactType.id,
                //   },
                // },
                updateName: {
                  firstName: {
                    type: "String",
                    value: firstName,
                  },
                  middleName: {
                    type: "String",
                    value: middleName,
                  },
                  lastName: {
                    type: "String",
                    value: lastName,
                  },
                },
                setNameTitle: {
                  nameTitle: {
                    type: "String!",
                    value: nameTitle,
                  },
                },
                updateEmail: {
                  emailId: {
                    type: "UUID!",
                    value: contactEmail.id,
                  },
                  email: {
                    type: "String",
                    value: contactEmail.email,
                  },
                  emailTypeId: {
                    type: "UUID",
                    value: contactEmail.emailTypeId,
                  },
                },
              },
              collectionQueries: collectionQueries,
            });

            if (
              isDefault &&
              this.existingContact &&
              !this.existingContact.isDefault
            ) {
              await this.actions.updatePayorAccount({
                variables: {
                  payorAccountId: {
                    type: "UUID!",
                    value: this.clientId,
                    unincludeToFields: true,
                  },
                },
                queries: {
                  setDefaultContactInformation: {
                    contactInformationId: {
                      type: "UUID!",
                      value: this.contactInformation.id,
                    },
                  },
                },
              });
            }
          } else {
            const createMainPhone = await this.actions.createPhone({
              phone: {
                type: "String!",
                value: this.sanitizePhoneString(mainPhone.phone),
                unincludeToFields: true,
              },
              areaCode: {
                type: "String",
                value: this.sanitizePhoneString(mainPhone.areaCode),
                unincludeToFields: true,
              },
            });
            const createMainFax = await this.actions.createPhone({
              phone: {
                type: "String!",
                value: this.sanitizePhoneString(mainFax.phone),
                unincludeToFields: true,
              },
              areaCode: {
                type: "String",
                value: this.sanitizePhoneString(mainFax.areaCode),
                unincludeToFields: true,
              },
            });

            const createEmail = await this.actions.createEmail({
              variables: {
                email: {
                  type: "String!",
                  value: contactEmail.email,
                },
              },
              queries: {
                setEmailType: {
                  emailTypeId: {
                    type: "UUID!",
                    value: contactEmail.emailTypeId,
                  },
                },
              },
            });

            if (createMainPhone) mainPhone.id = createMainPhone.id;
            if (createMainFax) mainFax.id = createMainFax.id;
            if (createEmail) contactEmail.id = createEmail.id;

            const createContactInfo =
              await this.actions.createContactInformation({
                variables: {
                  firstName: {
                    type: "String!",
                    value: firstName,
                  },
                  middleName: {
                    type: "String",
                    value: middleName,
                  },
                  lastName: {
                    type: "String!",
                    value: lastName,
                  },
                  nameTitle: {
                    type: "String",
                    value: nameTitle,
                  },
                },
                queries: {
                  // temporarily disable
                  // setContactType: {
                  //   contactTypeId: {
                  //     type: "UUID!",
                  //     value: contactType.id,
                  //   },
                  // },
                  setEmail: {
                    emailId: {
                      type: "UUID!",
                      value: contactEmail.id,
                    },
                  },
                },
                collectionQueries: [
                  {
                    setMainPhone: {
                      phoneId: {
                        type: "UUID!",
                        value: mainPhone.id,
                      },
                    },
                  },
                  {
                    setMainFax: {
                      phoneId: {
                        type: "UUID!",
                        value: mainFax.id,
                      },
                    },
                  },
                ],
              });

            if (createContactInfo)
              this.contactInformation.id = createContactInfo.id;

            let params = {
              variables: {
                payorAccountId: {
                  type: "UUID!",
                  value: this.clientId,
                  unincludeToFields: true,
                },
              },
              collectionQueries: [
                {
                  addContactInformation: {
                    contactInformationId: {
                      type: "UUID!",
                      value: this.contactInformation.id,
                    },
                  },
                },
              ],
            };

            if (isDefault) {
              params.collectionQueries.push({
                setDefaultContactInformation: {
                  contactInformationId: {
                    type: "UUID!",
                    value: this.contactInformation.id,
                  },
                },
              });
            }

            await this.actions.updatePayorAccount(params);
          }

          this.$emit("doSave");
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Problem has occurred while saving data.",
        });
      } finally {
        this.isLoading = false;
      }
    },
    doCancel() {
      this.$emit("doCancel");
    },
    async validateForm() {
      const validate = [
        await this.$refs.form.validateAsync(),
        await this.$refs.mainPhone.$refs.form.validateAsync(),
        await this.$refs.mainFax.$refs.form.validateAsync(),
      ];

      return validate.filter((bool) => !bool).length === 0;
    },
    cancelSaving() {
      if (
        this.existingContact &&
        !isEqual(this.contactInformation, this.existingContact)
      ) {
        var hasUnEqual = false;

        for (let key of Object.keys(this.contactInformation)) {
          if (
            key.toUpperCase() === "MAINPHONE" ||
            key.toUpperCase() === "MAINFAX"
          ) {
            if (
              this.sanitizePhoneString(
                this.contactInformation[key].areaCode
              ) !== this.existingContact[key].areaCode ||
              this.sanitizePhoneString(this.contactInformation[key].phone) !==
                this.existingContact[key].phone
            ) {
              hasUnEqual = true;
              break;
            }
          } else {
            if (
              !isEqual(this.contactInformation[key], this.existingContact[key])
            ) {
              hasUnEqual = true;
              break;
            }
          }
        }

        if (hasUnEqual) {
          this.promptAction("doCancel", "Cancel this activity?");
        } else {
          this.doCancel();
        }
      } else {
        this.doCancel();
      }
    },
    async saveContactInformation() {
      if (this.contactInformation.id) {
        this.promptAction(
          "doSave",
          "Are you sure you want to save the updates?"
        );
      } else {
        if (!this.editMode) {
          const validate = await this.validateForm();
          if (validate) this.$emit("addContact", this.contactInformation);
        } else {
          await this.doSave();
        }
      }
    },
    promptAction(action, message) {
      this.prompt.action = action;
      this.prompt.message = message;
      this.prompt.visibility = true;
    },
    async doPromptAction(action) {
      this.prompt.visibility = false;

      if (action.toUpperCase() === "DOCANCEL") {
        this[action]();
      } else {
        await this[action]();
      }
    },
  },
  async created() {
    try {
      this.isLoading = true;

      if (this.existingContact) {
        this.contactInformation = JSON.parse(
          JSON.stringify(this.existingContact)
        );
        this.isDefaultInitially = this.contactInformation.isDefault;
        await this.getEmailTypes();
      }

      await this.getContactTypes();
    } catch (err) {
      this.showNotifyMessage({
        type: "danger",
        message: "Unable to fetch data.",
      });
    } finally {
      this.isLoading = false;
    }
  },
};
