import { render, staticRenderFns } from "./index.vue?vue&type=template&id=55e1036b&"
import script from "./script.js?vue&type=script&lang=js&"
export * from "./script.js?vue&type=script&lang=js&"
import style0 from "./styles.sass?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VDataTable,VDialog,VList,VListItem,VListItemContent,VListItemGroup,VMenu})
