import { uuid } from "vue-uuid";

import Actions from "modules/actions/client-accounts-actions";

import ClientAddressModal from "modules/main/ClientsPhysicians/Clients/modals/ClientAddressModal";

export default {
  name: "ClientAddressInfo",
  components: {
    ClientAddressModal,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    editMode: {
      required: false,
      type: Boolean,
    },
    clientId: {
      required: false,
    },
  },
  data() {
    return {
      actions: new Actions(),
      addressTypes: [],
      defaultClientAddress: "",
      clientAddressModalVisibility: false,
      isLoading: false,
      selectedAddress: null,
      promptData: null,
      promptVisibility: false,
      promptMessage: "",
      rules: {
        required: (val) =>
          !["", null, undefined].includes(val) || "This field is required.",
      },
      columns: [
        {
          text: "Default",
          value: "default",
          sortable: false,
        },
        {
          text: "Address Type",
          value: "addressType",
          sortable: false,
        },
        {
          text: "Country",
          value: "country",
          sortable: false,
        },
        {
          text: "Address",
          value: "fullAddress",
          sortable: false,
        },
        {
          text: "Main Phone",
          value: "mainPhone",
          sortable: false,
        },
        {
          text: "Main Fax",
          value: "mainFax",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ],
      footerProps: {
        showFirstLastPage: true,
        "items-per-page-options": [5, 10, 15, 20],
      },
    };
  },
  computed: {
    __ClientAddresses: {
      get() {
        return this.data;
      },
      set(value) {
        this.$emit("update:data", value);
      },
    },
    getAddresses() {
      return this.data.map((item) => {
        const {
          id,
          addressType,
          isDefault,
          archived,
          city,
          country,
          line1,
          line2,
          mainFax,
          mainPhone,
          postalCode,
          stateProvince,
        } = item;

        return {
          id,
          itemData: item,
          addressType: addressType ? addressType.label || "--" : "--",
          default: isDefault ? "Yes" : "No",
          fullAddress: `${line1}, ${city}, ${stateProvince} ${postalCode}`,
          line1: line1 || "--",
          line2: line2 || "--",
          city: city || "--",
          stateProvince: stateProvince || "--",
          postalCode: postalCode || "--",
          country: country || "--",
          mainPhone: mainPhone.areaCode && mainPhone.phone
            ? `(${mainPhone.areaCode}) ${mainPhone.phone}` || "--"
            : "--",
          mainFax:  mainFax.areaCode && mainFax.phone
            ? `(${mainFax.areaCode}) ${mainFax.phone}` || "--"
            : "--",
          status: archived ? "Deactivated" : "Active",
        };
      });
    },
  },
  methods: {
    async getAddressTypes() {
      try {
        if (!this.addressTypes.length) {
          this.addressTypes = [
            { label: "Fetching address types...", disabled: true },
          ];

          const result = await this.actions.getAllAddressTypes({
            filter: {
              forBilling: true,
            },
          });

          if (result) {
            this.addressTypes = result.map((item) => {
              return {
                id: item.id,
                label: item.label,
              };
            });
          } else {
            this.showNotifyMessage({
              type: "danger",
              message: "Unable to fetch data.",
            });
          }
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Unable to fetch data.",
        });
      }
    },
    setDefaultClientAddress() {
      this.defaultClientAddress = this.__ClientAddresses.find(
        ({ isDefault }) => isDefault === true
      )?.uuid;
    },
    addClientAddress(address) {
      let {
        isDefault,
        mainPhone,
        mainFax,
        line1,
        city,
        stateProvince,
        postalCode,
        country,
        addressType,
      } = address;

      address.mainPhone.areaCode = this.sanitizePhoneString(mainPhone.areaCode);
      address.mainFax.areaCode = this.sanitizePhoneString(mainFax.areaCode);

      if (isDefault) {
        this.__ClientAddresses.map((addr) => {
          addr.isDefault = false;
          return addr;
        });
      }

      if (!this.selectedAddress) {
        this.__ClientAddresses.push({
          ...address,
          id: null,
          uuid: uuid.v1(),
        });
      } else {
        const hasChangeAddressType =
          this.__ClientAddresses.find((addr) => addr.uuid == address.uuid)
            ?.addressType?.label !== addressType?.label;

        this.__ClientAddresses = this.__ClientAddresses.map((addr) => {
          if (addr.uuid == address.uuid) {
            return {
              ...address,
            };
          }
          return addr;
        });
        if (hasChangeAddressType) {
          this.$eventBus.$emit("removePreferredMethod", address.uuid);
        } else {
          this.$nextTick(() => {
            const displayLbl = `${line1}, ${city}, ${stateProvince} ${postalCode}, ${country}`;
            this.$eventBus.$emit("setPreferredMethod", {
              uuid: address.uuid,
              displayLbl,
            });
          });
        }
      }
      this.setDefaultClientAddress();
      this.setModalVisibility(false);
    },
    deleteClientAddress(uuid) {
      this.__ClientAddresses.map((item) => {
        item.isDefault = false;

        return item;
      });

      const findIndex = this.__ClientAddresses.findIndex(
        (item) => item.uuid === uuid
      );

      if (uuid === this.defaultClientAddress) {
        if (findIndex > -1) this.__ClientAddresses.splice(findIndex, 1);

        if (this.__ClientAddresses[0]) {
          this.defaultClientAddress = this.__ClientAddresses[0].uuid;
          this.__ClientAddresses[0].isDefault = true;
        }
      } else {
        if (findIndex > -1) this.__ClientAddresses.splice(findIndex, 1);

        const defaultIndex = this.__ClientAddresses.findIndex(
          (item) => item.uuid === this.defaultClientAddress
        );

        if (defaultIndex > -1) {
          this.defaultClientAddress = this.__ClientAddresses[defaultIndex].uuid;
          this.__ClientAddresses[defaultIndex].isDefault = true;
        }
      }
      this.$eventBus.$emit("removePreferredMethod", uuid);
    },
    selectDefault(uuid) {
      this.__ClientAddresses.map((item) => {
        item.isDefault = false;

        return item;
      });

      const findIndex = this.__ClientAddresses.findIndex(
        (item) => item.uuid === uuid
      );

      if (findIndex > -1) this.__ClientAddresses[findIndex].isDefault = true;
    },
    setModalVisibility(visible = false) {
      this.clientAddressModalVisibility = visible;
      if (!visible) this.selectedAddress = null;
    },
    editClientAddress(address) {
      this.selectedAddress = address;
      this.setModalVisibility(true);
    },
    async saveClientAddress() {
      this.setModalVisibility(false);
      this.showNotifyMessage({
        type: "success",
        message: "Client Address saved.",
      });
      await this.getClientAddresses();

      if(this.editMode){
        this.$eventBus.$emit("refreshClientContractualInfo");
      }
    },
    async archiveAddress(addressId) {
      try {
        await this.actions.archiveAddress({
          id: {
            type: "UUID!",
            value: addressId,
          },
        });

        this.showNotifyMessage({
          type: "success",
          message: "Client Address set as deactivated.",
        });
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Problem has occurred while saving data.",
        });
      } finally {
        this.promptVisibility = false;
        this.getClientAddresses();
      }
    },
    async deArchiveAddress(addressId) {
      try {
        await this.actions.deArchiveAddress({
          id: {
            type: "UUID!",
            value: addressId,
          },
        });

        this.showNotifyMessage({
          type: "success",
          message: "Client Address Activated.",
        });
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Problem has occurred while saving data.",
        });
      } finally {
        this.promptVisibility = false;
        this.getClientAddresses();
      }
    },
    setPromptModalVisibility({ action, id, message }) {
      this.promptVisibility = true;
      this.promptMessage = message;
      this.promptData = {
        action,
        id,
      };
    },
    async setPromptAction(data) {
      const { action, id } = data;
      await this[action](id);
    },
    async getClientAddresses() {
      try {
        this.isLoading = true;

        const result = await this.actions.getPayorAccount({
          variables: {
            property: {
              id: {
                type: "UUID!",
                value: this.clientId,
              },
            },
          },
          limitData: [
            this.buildSubQuery("defaultAddress", []),
            this.buildSubQuery("addresses(archive: true)", [
              "line1",
              "line2",
              "city",
              "postalCode",
              "stateProvince",
              "country",
              this.buildSubQuery("addressType", ["label"]),
              this.buildSubQuery("mainPhone", ["phone", "areaCode"]),
              this.buildSubQuery("mainFax", ["phone", "areaCode"]),
              "archived",
            ]),
          ],
        });

        if (result) this.populateClientAddresses(result);
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Unable to fetch data.",
        });
      } finally {
        this.isLoading = false;
      }
    },
    populateClientAddresses(data) {
      if (data.addresses && data.addresses.length)
        this.__ClientAddresses = data.addresses.map((item) => {
          let isDefault = false;

          if (data.defaultAddress) {
            if (item.id === data.defaultAddress.id) isDefault = true;
          }

          const {
            id,
            line1,
            line2,
            city,
            postalCode,
            stateProvince,
            country,
            addressType,
            mainPhone,
            mainFax,
            archived,
          } = item;

          return {
            id,
            isDefault,
            line1,
            line2,
            city,
            postalCode,
            stateProvince,
            country,
            addressType: addressType
              ? {
                  id: addressType.id,
                  label: addressType.label,
                  value: addressType.id
                }
              : {
                  id: null,
                  label: "",
                },
            mainPhone: mainPhone
              ? {
                  id: mainPhone.id,
                  phone: mainPhone.phone,
                  areaCode: mainPhone.areaCode,
                }
              : {
                  id: null,
                  phone: "",
                  areaCode: "",
                },
            mainFax: mainFax
              ? {
                  id: mainFax.id,
                  phone: mainFax.phone,
                  areaCode: mainFax.areaCode,
                }
              : {
                  id: null,
                  phone: "",
                  areaCode: "",
                },
            archived,
          };
        });

    },
  },

  created() {
    if (this.editMode) {
      this.columns.splice(this.columns.length - 1, 0, {
        text: "Status",
        align: "center",
        value: "status",
        sortable: false,
      });
    }
  },
};
