import isEqual from "lodash/isEqual";

import Test from "store/models/Test";

import clientAccountActions from "modules/actions/client-accounts-actions";

import startCase from "lodash/startCase";

const METHOD_TYPE_ADDRESS = "ADDRESS";
const METHOD_TYPE_FAX = "FAX";
const METHOD_TYPE_EMAIL = "EMAIL";

export default {
  name: "ClientContractualModal",
  props: {
    contractualInformations: {
      required: true,
      type: Array,
    },
    existingContract: {
      required: false,
      type: Object,
    },
    clientId: {
      type: String,
    },
    editMode: {
      required: false,
      type: Boolean,
    },
    addressInformations: {
      type: Array,
    },
    contactInformations: {
      type: Array,
    },
  },
  data() {
    return {
      actions: new clientAccountActions(),
      testModel: Test,
      isLoading: false,
      statementBillingTerms: [],
      billingStatementTypes: [],
      vbsPreferredMethodTypeEnums: [],
      paymentTerms: [],
      paymentTypes: [],
      prompt: {
        visibility: false,
        message: "",
        action: "",
      },
      rules: {
        required: (val) => {
          if (
            val === null ||
            val === undefined ||
            val.length === 0 ||
            val === "" ||
            val?.value === ""
          ) {
            return "This field is required";
          }
          return true;
        },
      },
      contract: {
        id: null,
        billingStatementType: "",
        statementBillingTerm: "",
        statementBillingTermOther: "",
        paymentTerm: {},
        paymentTermOther: "",
        paymentType: {},
        paymentTypeOther: "",
        orderCode: "",
        methodType: null,
        selectedPreferredMethod: {
          id: null,
          label: null,
        },
        test: {
          id: null,
          label: "",
        },
        feeSchedule: "",
      },
      preferredItems: [],
    };
  },
  watch: {
    "contract.selectedPreferredMethod.id": function (selectedID) {
      this.setPreferredMethodLabel(selectedID);
    },
  },
  computed: {
    disableField() {
      return this.isLoading;
    },
    headerLabel() {
      if (!this.editMode) return "Contractual Information Details";

      return (
        (this.existingContract ? "Edit" : "Add") + " Contractual Information"
      );
    },
    btnLabel() {
      return this.existingContract ? "Save" : "Add";
    },

    preferredLbl() {
      const opt = this.contract?.methodType?.text.toUpperCase();
      if (opt == METHOD_TYPE_EMAIL) {
        return "email address";
      }

      if (opt == METHOD_TYPE_EMAIL) {
        return "fax number";
      }

      return "address";
    },

    getPreferredItems() {
      const opt = this.contract?.methodType?.text.toUpperCase();
      if (!opt) return [];

      if (opt.includes(METHOD_TYPE_ADDRESS)) {
        this.preferredItems = [...this.addressInformations]
          .filter((item) => item.addressType.label.toUpperCase() === opt)
          .map((item) => {
            item.displayLbl = `${item.line1}, ${item.city}, ${item.stateProvince} ${item.postalCode}, ${item.country}`;
            item.selectedId = item.id;
            return item;
          });
        return this.preferredItems;
      } else {
        this.preferredItems = [...this.contactInformations].map((item) => {
          const { mainFax, contactEmail } = item;

          item.displayLbl =
            opt !== METHOD_TYPE_EMAIL
              ? `(${mainFax.areaCode}) ${mainFax.phone}`
              : contactEmail.email;

          item.selectedId =
            opt !== METHOD_TYPE_EMAIL ? mainFax.id : contactEmail.id;
          return item;
        });

        return this.preferredItems;
      }
    },
  },
  methods: {
    setPreferredMethodLabel(selectedID) {
      this.contract.selectedPreferredMethod.label = this.preferredItems.find(
        ({ uuid }) => uuid == selectedID
      )?.displayLbl;
    },
    validateTermIfAlreadySelected(term, ID = null, UUID = null) {
      let contractList = [...this.contractualInformations];
      if (ID) contractList = contractList.filter(({ id }) => id !== ID);
      else if (UUID)
        contractList = contractList.filter(({ uuid }) => uuid !== UUID);

      return contractList.some(({ statementBillingTerm }) => {
        return statementBillingTerm == term;
      });
    },
    getPreferredSelectedId(selectedPreferredMethod = null) {
      const opt = this.contract?.methodType?.text.toUpperCase();
      const preferredItem = this.preferredItems.find((item) => {
        if (opt.includes(METHOD_TYPE_ADDRESS))
          return item?.id === selectedPreferredMethod?.id;

        if (opt === METHOD_TYPE_FAX)
          return item.mainFax.id == selectedPreferredMethod?.id;
        else return item.contactEmail.id == selectedPreferredMethod?.id;
      });

      if (opt != METHOD_TYPE_EMAIL && opt != METHOD_TYPE_FAX) {
        return preferredItem.id;
      }

      return preferredItem.selectedId;
    },

    async doSave() {
      try {
        this.isLoading = true;

        const validate = await this.validateForm();

        const hasSelected = !this.contract.selectedPreferredMethod.id;
        if (hasSelected) {
          this.showNotifyMessage({
            message: "Please select one preferred method.",
            type: "danger",
          });
          return;
        }

        const hasSameTerm = this.validateTermIfAlreadySelected(
          this.contract.statementBillingTerm,
          this.contract.id
        );

        if (hasSameTerm) {
          this.showNotifyMessage({
            message: "Billing term should be unique",
            type: "danger",
          });
          return;
        }

        if (validate) {
          const {
            billingStatementType,
            statementBillingTerm,
            methodType,
            selectedPreferredMethod,
            feeSchedule,
          } = this.contract;

          const selectedId = this.getPreferredSelectedId(
            selectedPreferredMethod
          );

          if (this.contract.id) {
            await this.actions.updateBCCI({
              variables: {
                billingClientContractualInformationId: {
                  type: "UUID!",
                  value: this.contract.id,
                  unincludeToFields: true,
                },
              },
              queries: {
                updateBillingStatementType: {
                  billingStatementType: {
                    type: "BillingStatementType",
                    value: billingStatementType,
                  },
                },
                updateStatementBillingTerm: {
                  statementBillingTerm: {
                    type: "StatementBillingTerm",
                    value: statementBillingTerm,
                  },
                },
                updateFeeSchedule: {
                  feeSchedule: {
                    type: "String",
                    value: feeSchedule,
                  },
                },
              },
              collectionQueries: [
                {
                  updatePreferredMethodType: {
                    methodType: {
                      type: "VbsPreferredMethodTypeEnum!",
                      value: methodType.value,
                    },

                    selectedId: {
                      type: "UUID!",
                      value: selectedId,
                    },

                    mutationReturn: ["success"],
                  },
                },
              ],
            });
          } else {

            let paramCreateBillingInput = {
              preferredMethodType: {
                methodType: methodType.value,
                selectedId: selectedId,
              },
              statementBillingTerm: statementBillingTerm,
            };

            const variables = {
              input: {
                type: "BillingClientContractualInformationInput",
                value: paramCreateBillingInput,
                unincludeToFields: true,
              },
            };

            const queries = {
              updateBillingStatementType: {
                billingStatementType: {
                  type: "BillingStatementType",
                  value: billingStatementType,
                },
              },
              updateFeeSchedule: {
                feeSchedule: {
                  type: "String",
                  value: feeSchedule,
                },
              },
            };

            const createBCCI = await this.actions.createBCCI({
              variables,
              queries,
            });
            if (createBCCI) this.contract.id = createBCCI.id;

            await this.actions.updatePayorAccount({
              variables: {
                payorAccountId: {
                  type: "UUID!",
                  value: this.clientId,
                  unincludeToFields: true,
                },
              },
              queries: {
                addContractualInformation: {
                  billingClientContractualInformationId: {
                    type: "UUID!",
                    value: this.contract.id,
                  },
                },
              },
            });
          }

          this.$emit("doSave");
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Problem has occurred while saving data.",
        });
      } finally {
        this.isLoading = false;
      }
    },
    doCancel() {
      this.$emit("doCancel");
    },
    async validateForm() {
      const validate = [await this.$refs.form.validateAsync()];

      return validate.filter((bool) => !bool).length === 0;
    },
    async getEnum(enumName) {
      try {
        if (!this[`${this.formatToCamelCase(enumName)}s`].length) {
          this[`${this.formatToCamelCase(enumName)}s`] = [
            {
              text: `Fetching ${this.formaText(enumName)}s...`,
              disabled: true,
            },
          ];

          const result = await this.actions.getEnumValues(enumName);

          if (result) {
            if (enumName.toUpperCase() === "STATEMENTBILLINGTERM") {
              this[`${this.formatToCamelCase(enumName)}s`] =
                result.enumValues.map((item) => {
                  return {
                    text:
                      item.name.toUpperCase() === "BIMONTHLY"
                        ? "Bi-Monthly"
                        : item.name,
                    value: item.name,
                  };
                });
            } else {
              this[`${this.formatToCamelCase(enumName)}s`] =
                result.enumValues.map((item) => {
                  return {
                    text: startCase(
                      item.name.toLowerCase().replaceAll("_", " ")
                    ),
                    value: item.name,
                  };
                });
            }
          } else {
            this.showNotifyMessage({
              type: "danger",
              message: `Unable to fetch data.`,
            });
          }
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: `Unable to fetch data.`,
        });
      }
    },
    async getPaymentTypes() {
      if (this.paymentTypes.length) return;

      try {
        const result = await this.actions.getPaymentTypes({
          limitData: ["label"],
          order: {
            by: "orderByLabel",
            order: "ASC",
          },
        });

        if (result) {
          this.paymentTypes = result.map((item) => {
            return {
              text: item.label,
              value: item.id,
            };
          });
        } else {
          this.showNotifyMessage({
            type: "danger",
            message: `Unable to fetch data.`,
          });
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: `Unable to fetch data.`,
        });
      }
    },
    async getPaymentTerms() {
      if (this.paymentTerms.length) return;

      try {
        const result = await this.actions.getPaymentTerms({
          limitData: ["label"],
          order: {
            by: "orderByLabel",
            order: "ASC",
          },
        });

        if (result) {
          this.paymentTerms = result.map((item) => {
            return {
              text: item.label,
              value: item.id,
            };
          });
        } else {
          this.showNotifyMessage({
            type: "danger",
            message: `Unable to fetch data.`,
          });
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: `Unable to fetch data.`,
        });
      }
    },

    formatToCamelCase(str) {
      return str.charAt(0).toLowerCase() + str.slice(1);
    },
    formaText(value) {
      const result = value.replace(/([A-Z])/g, " $1");
      return result.toLowerCase();
    },

    cancelSaving() {
      if (
        this.existingContract &&
        !isEqual(this.contract, this.existingContract)
      ) {
        this.promptAction("doCancel", "Cancel this activity?");
      } else {
        this.doCancel();
      }
    },
    async saveContract() {
      if (this.contract.id) {
        this.promptAction(
          "doSave",
          "Are you sure you want to save the updates?"
        );
      } else {
        if (this.editMode) {
          await this.doSave();
        } else {
          const validate = await this.validateForm();
          const hasSelected = !this.contract.selectedPreferredMethod.id;
          const hasSameTerm = this.validateTermIfAlreadySelected(
            this.contract.statementBillingTerm,
            null,
            this.contract.uuid
          );

          if (validate & hasSelected) {
            this.showNotifyMessage({
              message: "Please select one preferred method.",
              type: "danger",
            });
            return;
          }

          if (hasSameTerm) {
            this.showNotifyMessage({
              message: "Billing term should be unique",
              type: "danger",
            });
            return;
          }
          this.setPreferredMethodLabel(
            this.contract.selectedPreferredMethod.id
          );
          if (validate) this.$emit("addContract", this.contract);
        }
      }
    },
    promptAction(action, message) {
      this.prompt.action = action;
      this.prompt.message = message;
      this.prompt.visibility = true;
    },
    async doPromptAction(action) {
      this.prompt.visibility = false;

      if (action.toUpperCase() === "DOCANCEL") {
        this[action]();
      } else {
        await this[action]();
      }
    },
  },
  async created() {
    try {
      this.isLoading = true;

      if (this.existingContract) {
        this.contract = JSON.parse(JSON.stringify(this.existingContract));
        await this.getEnum("BillingStatementType");
        await this.getEnum("StatementBillingTerm");
        await this.getEnum("VbsPreferredMethodTypeEnum");
        await this.getPaymentTypes();
        await this.getPaymentTerms();
      }
    } catch (err) {
      this.showNotifyMessage({
        type: "danger",
        message: "Unable to fetch data.",
      });
    } finally {
      this.isLoading = false;
    }
  },
};
