import ClientAccountsActions from "modules/actions/client-accounts-actions";

export default {
  name: "FeeScheduleModal",
  props: {
    existingFeeSchedule: {
      required: false,
      type: Object,
    },
    clientId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      actions: new ClientAccountsActions(),
      isLoading: false,
      isDefaultInitially: false,
      prompt: {
        visibility: false,
        message: "",
        action: "",
      },
      rules: {
      },
      feeSchedule: {
        id: null,
        feeName: "",
        test: {
          id: null,
          label: "",
        },
        effectivityDate: "",
        expirationDate: "",
        baseRate: "",
        discountPercentage: ""
      },
    };
  },
  methods: {
    doCancel() {
      this.$emit("doCancel");
    },
    async validateForm() {
      const validate = [
        await this.$refs.form.validateAsync(),
      ];

      return validate.filter((bool) => !bool).length === 0;
    },

    promptAction(action, message) {
      this.prompt.action = action;
      this.prompt.message = message;
      this.prompt.visibility = true;
    },
    async doPromptAction(action) {
      this.prompt.visibility = false;

      if (action.toUpperCase() === "DOCANCEL") {
        this[action]();
      } else {
        await this[action]();
      }
    },
    cancelSaving() {
      this.doCancel();
    },
  },
  async created() {
    try {
      this.isLoading = true;

      if (this.existingFeeSchedule) {
        this.feeSchedule = JSON.parse(
          JSON.stringify(this.existingFeeSchedule)
        );
      }

    } catch (err) {
      this.showNotifyMessage({
        type: "danger",
        message: "Unable to fetch data.",
      });
    } finally {
      this.isLoading = false;
    }
  },
};
