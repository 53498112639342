import isEqual from "lodash/isEqual";
import { uuid } from "vue-uuid";

import actions from "modules/actions/client-accounts-actions";

import PhysicianModal from "modules/main/ClientsPhysicians/Clients/modals/PhysicianModal";

export default {
  name: "ClientPhysicianModal",
  components: {
    PhysicianModal,
  },
  props: {
    physicians: {
      required: true,
      type: Array,
    },
    payorAccountNpi: {
      required: true,
      type: String,
    },
    existingPhysician: {
      required: false,
      type: Object,
    },
    linkedPhysicians: {
      required: true,
      type: Array,
    },
    clientId: {
      required: false,
      type: String,
    },
    editMode: {
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {
      actions: new actions(),
      physicianModalVisibility: false,
      hasSelectedPhysician: false,
      isLoading: false,
      menu1: "",
      menu2: "",
      date1: "",
      date2: "",
      prompt: {
        visibility: false,
        message: "",
        action: "",
      },
      licenseStatuses: [],
      rules: {
        required: (val) => {
          if (typeof val === "object" && val !== null)
            return (
              (val && val.id && val.id !== null) || "This field is required"
            );

          return (
            (val !== null && val.length !== 0) || "This field is required."
          );
        },
        validateNpi: async (val, uuid, physicianNpi) =>
          (await this.doValidateNpiNumber(val, uuid, physicianNpi)) ||
          "This field is required.",
        validateLicenseNumber: async (val, physicianId) =>
          (await this.doValidateLicenseNumber(val, physicianId)) ||
          "This field is required.",
        validateEmail: (val) =>
          this.validateEmail(val) || "Invalid email format.",
        validDate: (val) => this.isValidDate(val) || "Invalid date format.",
        validTerminationDate: (val, effectivityDate) => {
          const terminationDate = new Date(val);
          const effectDate = new Date(effectivityDate);

          if (effectivityDate === null || !effectivityDate.length) return true;

          return (
            terminationDate >= effectDate ||
            "Termination date must not be less than the effective date."
          );
        },
      },
      physician: {
        id: null,
        physicianId: "",
        taxonomy: "",
        firstName: "",
        middleName: "",
        lastName: "",
        npi: {
          id: null,
          npiNumber: "",
        },
        stateMedicalLicense: {
          id: null,
          licenseNumber: "",
        },
        licenseState: "",
        licenseStatus: "",
        defaultPhone: {
          id: null,
          phone: "",
        },
        defaultEmail: {
          id: null,
          email: "",
        },
        defaultAddress: {
          id: null,
          line1: "",
          line2: "",
          city: "",
          stateProvince: "",
          postalCode: "",
          country: "",
        },
        phones: [
          {
            id: null,
            uuid: uuid.v1(),
            isDefault: true,
            phone: "",
            areaCode: "",
          },
        ],
        emails: [
          {
            id: null,
            uuid: uuid.v1(),
            isDefault: true,
            email: "",
            emailTypeId: null,
          },
        ],
        effectivityDate: "",
        terminationDate: "",
      },
    };
  },
  computed: {
    fieldDisabled() {
      return !this.editMode || this.isLoading || this.hasSelectedPhysician;
    },
    headerLabel() {
      if (!this.editMode) return "Physician Details";

      return (this.existingPhysician ? "Edit" : "Add") + " Physician";
    },
  },
  methods: {
    async doSave() {
      try {
        this.isLoading = true;

        const validate = await this.validateForm();

        if (validate) {
          const {
            taxonomy,
            firstName,
            middleName,
            lastName,
            npi,
            stateMedicalLicense,
            licenseState,
            licenseStatus,
            defaultPhone,
            defaultEmail,
            phones,
            emails,
            effectivityDate,
            terminationDate,
          } = this.physician;

          if (this.physician.id) {
            if (this.hasSelectedPhysician) {
              await this.actions.updatePayorAccount({
                variables: {
                  payorAccountId: {
                    type: "UUID!",
                    value: this.clientId,
                    unincludeToFields: true,
                  },
                },
                collectionQueries: [
                  {
                    addPhysician: {
                      physicianId: {
                        type: "UUID!",
                        value: this.physician.id,
                      },
                    },
                  },
                ],
              });
            } else {
              let physicianParams = {
                params: {
                  physicianId: {
                    type: "UUID!",
                    value: this.physician.id,
                    unincludeToFields: true,
                  },
                },
                queries: {
                  updateName: {
                    firstName: {
                      type: "String",
                      value: firstName,
                    },
                    middleName: {
                      type: "String",
                      value: middleName,
                    },
                    lastName: {
                      type: "String",
                      value: lastName,
                    },
                  },
                  updateTaxonomy: {
                    taxonomy: {
                      type: "String",
                      value: taxonomy,
                    },
                  },
                  updateNpi: {
                    npiNumber: {
                      type: "String",
                      value: npi.npiNumber,
                    },
                  },
                  updateStateMedicalLicense: {
                    licenseNumber: {
                      type: "String",
                      value: stateMedicalLicense.licenseNumber,
                    },
                  },
                  updateLicenseState: {
                    licenseState: {
                      type: "String",
                      value: licenseState,
                    },
                  },
                  updateLicenseStatus: {
                    licenseStatus: {
                      type: "LicenseStatus",
                      value: licenseStatus,
                    },
                  },
                },
                collectionQueries: [{
                  updateValidityDates: {
                    effectivityDate: {
                      type: "String",
                      value: effectivityDate
                        ? this.$options.filters.getTimeDate(
                          effectivityDate,
                          "YYYY-MM-DD HH:mm:ss",
                          false,
                          null,
                          this.getTimezoneOffset({
                            date: effectivityDate,
                          }),
                        ).utc
                        : "",
                      unincludeToFields: true,
                    },
                    terminationDate: {
                      type: "String",
                      value: terminationDate
                        ? this.$options.filters.getTimeDate(
                            terminationDate,
                            "YYYY-MM-DD HH:mm:ss",
                            false,
                            null,
                            {
                              days: 1,
                              ...this.getTimezoneOffset({
                                date: terminationDate,
                                minusSeconds: 1,
                              }),
                            }
                          ).utc
                        : "",
                      unincludeToFields: true,
                    },
                    mutationReturn: ["success", "errors"],
                  }
                }],
              };

              // update phones
              for (let phone of phones) {
                if (phone.id) {
                  physicianParams.collectionQueries.push({
                    updatePhone: {
                      phoneId: {
                        type: "UUID!",
                        value: phone.id,
                      },
                      phone: {
                        type: "String",
                        value: this.sanitizePhoneString(phone.phone),
                      },
                      areaCode: {
                        type: "String",
                        value: this.sanitizePhoneString(phone.areaCode),
                      },
                    },
                  });
                } else {
                  const createPhone = await this.actions.createPhone({
                    phone: {
                      type: "String!",
                      value: this.sanitizePhoneString(phone.phone),
                      unincludeToFields: true,
                    },
                    areaCode: {
                      type: "String",
                      value: this.sanitizePhoneString(phone.areaCode),
                      unincludeToFields: true,
                    },
                  });

                  physicianParams.collectionQueries.push({
                    addPhone: {
                      phoneId: {
                        type: "UUID!",
                        value: createPhone.id,
                      },
                    },
                  });

                  phone.id = createPhone.id;
                }

                if (phone.isDefault)
                  physicianParams.queries["setDefaultPhone"] = {
                    phoneId: {
                      type: "UUID!",
                      value: phone.id,
                    },
                  };
              }

              // unset removed phones
              for (let phone of this.existingPhysician.phones) {
                const isExisting =
                  phones.filter((item) => item.id == phone.id).length > 0;

                if (!isExisting)
                  physicianParams.collectionQueries.push({
                    deletePhone: {
                      phoneId: {
                        type: "UUID!",
                        value: phone.id,
                      },
                    },
                  });
              }

              // update emails
              for (let email of emails) {
                if (email.id) {
                  physicianParams.collectionQueries.push({
                    updateEmail: {
                      emailId: {
                        type: "UUID!",
                        value: email.id,
                      },
                      email: {
                        type: "String",
                        value: email.email,
                      },
                      emailTypeId: {
                        type: "UUID",
                        value: email.emailTypeId,
                      },
                    },
                  });
                } else {
                  const createEmail = await this.actions.createEmail({
                    variables: {
                      email: {
                        type: "String!",
                        value: email.email,
                      },
                    },
                    queries: {
                      setEmailType: {
                        emailTypeId: {
                          type: "UUID!",
                          value: email.emailTypeId,
                        },
                      },
                    },
                  });

                  physicianParams.collectionQueries.push({
                    addEmail: {
                      emailId: {
                        type: "UUID!",
                        value: createEmail.id,
                      },
                    },
                  });

                  email.id = createEmail.id;
                }

                if (email.isDefault)
                  physicianParams.queries["setDefaultEmail"] = {
                    emailId: {
                      type: "UUID!",
                      value: email.id,
                    },
                  };
              }

              // unset removed emails
              for (let email of this.existingPhysician.emails) {
                const isExisting =
                  emails.filter((item) => item.id == email.id).length > 0;

                if (!isExisting)
                  physicianParams.collectionQueries.push({
                    deleteEmail: {
                      emailId: {
                        type: "UUID!",
                        value: email.id,
                      },
                    },
                  });
              }

              await this.actions.updatePhysician(physicianParams);
            }
          } else {
            const createNpi = await this.actions.createNpi({
              npiNumber: {
                type: "String!",
                value: npi.npiNumber,
              },
            });

            const createSML = await this.actions.createStateMedicalLicense({
              licenseNumber: {
                type: "String!",
                value: stateMedicalLicense.licenseNumber,
              },
            });

            for (let phone of phones) {
              const createPhone = await this.actions.createPhone({
                phone: {
                  type: "String!",
                  value: this.sanitizePhoneString(phone.phone),
                  unincludeToFields: true,
                },
                areaCode: {
                  type: "String",
                  value: this.sanitizePhoneString(phone.areaCode),
                  unincludeToFields: true,
                },
              });

              phone.id = createPhone.id;
              if (phone.isDefault) defaultPhone.id = createPhone.id;
            }

            for (let email of emails) {
              const createEmail = await this.actions.createEmail({
                variables: {
                  email: {
                    type: "String!",
                    value: email.email,
                  },
                },
                queries: {
                  setEmailType: {
                    emailTypeId: {
                      type: "UUID!",
                      value: email.emailTypeId,
                    },
                  },
                },
              });

              email.id = createEmail.id;
              if (email.isDefault) defaultEmail.id = createEmail.id;
            }

            this.physician.npi.id = createNpi.id;
            this.physician.stateMedicalLicense.id = createSML.id;

            let params = {
              variables: {
                firstName: {
                  type: "String!",
                  value: firstName,
                },
                lastName: {
                  type: "String!",
                  value: lastName,
                },
              },
              queries: {
                updateTaxonomy: {
                  taxonomy: {
                    type: "String",
                    value: taxonomy,
                  },
                },
                setNpi: {
                  npiId: {
                    type: "UUID!",
                    value: npi.id,
                  },
                },
                setStateMedicalLicense: {
                  stateMedicalId: {
                    type: "UUID!",
                    value: stateMedicalLicense.id,
                  },
                },
                setDefaultPhone: {
                  phoneId: {
                    type: "UUID!",
                    value: defaultPhone.id,
                  },
                },
                setDefaultEmail: {
                  emailId: {
                    type: "UUID!",
                    value: defaultEmail.id,
                  },
                },
              },
              collectionQueries: [
                ...phones.map(phone => ({
                    addPhone: {
                      phoneId: {
                        type: "UUID!",
                        value: phone.id,
                      },
                    },
                })),
                ...emails.map(email => ({
                    addEmail: {
                      emailId: {
                        type: "UUID!",
                        value: email.id,
                      },
                    },
                })),
                {
                  updateValidityDates: {
                    effectivityDate: {
                      type: "String",
                      value: effectivityDate
                        ? this.$options.filters.getTimeDate(
                            effectivityDate,
                            "YYYY-MM-DD HH:mm:ss",
                            false,
                            null,
                            this.getTimezoneOffset({
                              date: effectivityDate,
                            }),
                          ).utc
                        : "",
                      unincludeToFields: true,
                    },
                    terminationDate: {
                      type: "String",
                      value: terminationDate
                        ? this.$options.filters.getTimeDate(
                            terminationDate,
                            "YYYY-MM-DD HH:mm:ss",
                            false,
                            null,
                            {
                              days: 1,
                              ...this.getTimezoneOffset({
                                date: terminationDate,
                                minusSeconds: 1,
                              }),
                            }
                          ).utc
                        : "",
                      unincludeToFields: true,
                    },
                    mutationReturn: ["success", "errors"]
                  },
                },
              ],
            };

            if (middleName.length)
              params.variables["middleName"] = {
                type: "String",
                value: middleName,
              };

            if (licenseState.length)
              params.queries["updateLicenseState"] = {
                licenseState: {
                  type: "String",
                  value: licenseState,
                },
              };

            if (licenseStatus.length)
              params.queries["updateLicenseStatus"] = {
                licenseStatus: {
                  type: "LicenseStatus",
                  value: licenseStatus,
                },
              };

            const createPhysician = await this.actions.createPhysician(params);

            this.physician.id = createPhysician.id;

            await this.actions.updatePayorAccount({
              variables: {
                payorAccountId: {
                  type: "UUID!",
                  value: this.clientId,
                  unincludeToFields: true,
                },
              },
              queries: {
                addPhysician: {
                  physicianId: {
                    type: "UUID!",
                    value: this.physician.id,
                  },
                },
              },
            });
          }

          this.$emit("doSave");
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Problem has occurred while saving data.",
        });
      } finally {
        this.isLoading = false;
      }
    },
    doCancel() {
      this.$emit("doCancel");
    },
    async validateForm() {
      const validate = [
        await this.$refs.form.validateAsync(),
        await this.$refs.physicianPhones.$refs.form.validateAsync(),
        await this.$refs.physicianEmails.$refs.form.validateAsync(),
      ];

      return validate.filter((bool) => !bool).length === 0;
    },
    async doValidateNpiNumber(npi, uuid, physicianNpi) {
      try {
        if (!/^\d{10}$/.test(npi)) {
          return "NPI number must have a min/max field length of 10.";
        }

        const validateNpiExternally = await this.validateNpiNumber(npi);
        if (typeof validateNpiExternally === "string") {
          return validateNpiExternally;
        } else {
          const result = await this.actions.searchNpi(npi);
          if (result.length) {
            return (
              result[0].id === physicianNpi.id || "NPI number already exists."
            );
          }

          return true;
        }
      } catch (err) {
        return "Invalid NPI number.";
      }
    },
    async doValidateLicenseNumber(licenseNumber, physicianId) {
      try {
        if (!licenseNumber.length) return "This field is required.";

        const result = await this.actions.getPhysicians({
          filter: {
            stateMedicalLicense: licenseNumber,
            enum_patternMatch: "EXACT",
          },
          limitData: ["physicianId"],
        });

        if (result.length && result[0].id !== physicianId)
          return "License number already exists.";

        return true;
      } catch (err) {
        throw Error(err);
      }
    },
    async getLicenseStatuses() {
      try {
        if (!this.licenseStatuses.length) {
          this.licenseStatuses = [
            { text: "Fetching license statues...", disabled: true },
          ];

          const result = await this.actions.getEnumValues("LicenseStatus");

          if (result) {
            this.licenseStatuses = result.enumValues.map((item) => {
              return {
                text: item.name,
                value: item.name,
              };
            });
          } else {
            this.showNotifyMessage({
              type: "danger",
              message: "Unable to fetch data.",
            });
          }
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Unable to fetch data.",
        });
      }
    },
    isExistingPhysician(physician) {
      return physician.id !== null;
    },
    displayPhysicianModal(visible = false) {
      this.physicianModalVisibility = visible;
    },
    parseDate(date) {
      if (!this.isValidDate(date)) return "";

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    closeDateModal(dataModel, menu, date) {
      this.physician[dataModel] = this.formatDate(date);
      this[menu] = false;
    },
    async selectedPhysician(e) {
      const { physician } = e;

      const {
        id,
        physicianId,
        taxonomy,
        firstName,
        middleName,
        lastName,
        npi,
        stateMedicalLicense,
        licenseState,
        licenseStatus,
        defaultPhone,
        defaultEmail,
        phones,
        emails,
        effectivityDate,
        terminationDate,
      } = physician;

      this.physician.id = id;
      this.physician.physicianId = physicianId || "";
      this.physician.taxonomy = taxonomy || "";
      this.physician.firstName = firstName || "";
      this.physician.middleName = middleName || "";
      this.physician.lastName = lastName || "";
      this.physician.npi = npi || {
        id: null,
        npiNumber: "",
      };
      this.physician.stateMedicalLicense = stateMedicalLicense || {
        id: null,
        licenseNumber: "",
      };
      this.physician.licenseState = licenseState || "";
      this.physician.licenseStatus = licenseStatus || "";
      this.physician.defaultPhone = defaultPhone || {
        id: null,
        phone: "",
      };
      this.physician.defaultEmail = defaultEmail || {
        id: null,
        email: "",
      };

      this.physician.phones = phones
        ? phones.map((phoneItem) => {
            var isDefault = false;
            const { id, phone, areaCode } = phoneItem;

            if (this.physician.defaultPhone.id === id) isDefault = true;

            return {
              id,
              uuid: uuid.v1(),
              isDefault,
              phone,
              areaCode: areaCode || "",
            };
          })
        : [
            {
              id: null,
              uuid: uuid.v1(),
              isDefault: true,
              phone: "",
              phoneTypeId: null,
              areaCode: "",
            },
          ];

      this.physician.emails = emails
        ? emails.map((emailItem) => {
            var isDefault = false;
            const { id, email, emailType } = emailItem;

            if (this.physician.defaultEmail.id === id) isDefault = true;

            return {
              id,
              uuid: uuid.v1(),
              isDefault,
              email,
              emailTypeId: emailType ? emailType.id : null,
            };
          })
        : [
            {
              id: null,
              uuid: uuid.v1(),
              isDefault: true,
              email: "",
              emailTypeId: null,
            },
          ];

      this.physician.effectivityDate = effectivityDate || "";
      this.physician.terminationDate = terminationDate || "";

      this.displayPhysicianModal(false);
      this.hasSelectedPhysician = true;

      if (!this.licenseStatuses.length) await this.getLicenseStatuses();
    },
    clearPhysician() {
      this.physician.id = null;
      this.physician.physicianId = "";
      this.physician.taxonomy = "";
      this.physician.firstName = "";
      this.physician.middleName = "";
      this.physician.lastName = "";
      this.physician.npi = {
        id: null,
        npiNumber: "",
      };
      this.physician.stateMedicalLicense = {
        id: null,
        licenseNumber: "",
      };
      this.physician.licenseState = "";
      this.physician.licenseStatus = "";
      this.physician.defaultPhone = {
        id: null,
        phone: "",
      };
      this.physician.defaultEmail = {
        id: null,
        email: "",
      };

      this.physician.phones = [
        {
          id: null,
          uuid: uuid.v1(),
          isDefault: true,
          phone: "",
          phoneTypeId: null,
        },
      ];

      this.physician.emails = [
        {
          id: null,
          uuid: uuid.v1(),
          isDefault: true,
          email: "",
          emailTypeId: null,
        },
      ];

      this.physician.effectivityDate = "";
      this.physician.terminationDate = "";

      this.hasSelectedPhysician = false;
    },
    cancelSaving() {
      if (
        this.existingPhysician &&
        !isEqual(this.physician, this.existingPhysician)
      ) {
        var hasUnEqual = false;
        for (let key of Object.keys(this.physician)) {
          if (key.toUpperCase() == "DEFAULTPHONE") {
            if (
              this.physician[key] !== null &&
              (this.physician[key].areaCode !==
                this.existingPhysician[key].areaCode ||
                this.physician[key].phone !== this.existingPhysician[key].phone)
            ) {
              hasUnEqual = true;
              break;
            }
          } else if (key.toUpperCase() == "PHONES") {
            if (
              !isEqual(
                this.physician[key].map((item) => {
                  const { phone, areaCode } = item;
                  return {
                    phone,
                    areaCode,
                  };
                }),
                this.existingPhysician[key].map((item) => {
                  const { phone, areaCode } = item;

                  return {
                    phone,
                    areaCode,
                  };
                })
              )
            ) {
              hasUnEqual = true;
              break;
            }
          } else {
            if (!isEqual(this.physician[key], this.existingPhysician[key])) {
              hasUnEqual = true;
              break;
            }
          }
        }

        if (hasUnEqual) {
          this.promptAction("doCancel", "Cancel this activity?");
        } else {
          this.doCancel();
        }
      } else {
        this.doCancel();
      }
    },
    async savePhysician() {
      if (this.physician.id) {
        this.promptAction(
          "doSave",
          "Are you sure you want to save the updates?"
        );
      } else {
        await this.doSave();
      }
    },
    promptAction(action, message) {
      this.prompt.action = action;
      this.prompt.message = message;
      this.prompt.visibility = true;
    },
    async doPromptAction(action) {
      this.prompt.visibility = false;

      if (action.toUpperCase() === "DOCANCEL") {
        this[action]();
      } else {
        await this[action]();
      }
    },
  },
  async created() {
    try {
      this.isLoading = true;

      this.existingPhysician.phones.map((item) => {
        item.areaCode = item.areaCode.length
          ? item.areaCode.substring(0, 3)
          : "";
        item.phone = item.phone.length ? item.phone.substring(0, 7) : "";
        return item;
      });

      if (this.existingPhysician) {
        this.physician = JSON.parse(JSON.stringify(this.existingPhysician));

        if (this.existingPhysician.licenseStatus)
          await this.getLicenseStatuses();
      }
    } catch (err) {
      this.showNotifyMessage({
        type: "danger",
        message: "Unable to fetch data.",
      });
    } finally {
      this.isLoading = false;
    }
  },
};
