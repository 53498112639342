import isEqual from "lodash/isEqual";
import startCase from "lodash/startCase";

import ClientAccountsActions from "modules/actions/client-accounts-actions";

import GeneralInfo from "modules/main/ClientsPhysicians/Clients/sections/Details/sections/GeneralInfo";
import ClientPhysician from "modules/main/ClientsPhysicians/Clients/sections/Details/sections/ClientPhysician";
import ClientAddressInfo from "modules/main/ClientsPhysicians/Clients/sections/Details/sections/ClientAddressInfo";
import ClientContactInfo from "modules/main/ClientsPhysicians/Clients/sections/Details/sections/ClientContactInfo";
import ClientContractualInfo from "modules/main/ClientsPhysicians/Clients/sections/Details/sections/ClientContractualInfo";
import ClientSales from "modules/main/ClientsPhysicians/Clients/sections/Details/sections/ClientSales";
import FeeSchedule from "modules/main/ClientsPhysicians/Clients/sections/Details/sections/FeeSchedule";

import { uuid } from "vue-uuid";

const METHOD_TYPE_ADDRESS = "ADDRESS";
const METHOD_TYPE_FAX = "FAX";
const METHOD_TYPE_EMAIL = "EMAIL";

export default {
  name: "EditClientAccount",
  components: {
    GeneralInfo,
    ClientPhysician,
    ClientAddressInfo,
    ClientContactInfo,
    ClientContractualInfo,
    ClientSales,
    FeeSchedule,
  },
  data() {
    return {
      actions: new ClientAccountsActions(),
      cancelModalVisibility: false,
      panels: [0, 1, 2, 3, 4, 5, 6],
      promptVisibility: false,
      promptMessage: "",
      promptData: null,
      sectionState: {
        generalInfo: {
          isSaving: false,
          isEditing: false,
        },
        clientAddresses: {
          isSaving: false,
          isEditing: false,
        },
        contactInformations: {
          isSaving: false,
          isEditing: false,
        },
        contractualInformations: {
          isSaving: false,
          isEditing: false,
        },
        clientPhysician: {
          isSaving: false,
          isEditing: false,
        },
        clientSalesAssignment: {
          isSaving: false,
          isEditing: false,
        },
        feeSchedule: {
          isSaving: false,
          isEditing: false,
        },
      },
      client: {
        id: null,
        generalInfo: {
          payorCode: "",
          subTypes: [""],
          payorName: "",
          npi: {
            id: null,
            npiNumber: "",
          },
          billType: "",
          tinNumber: "",
          taxonomy: "",
          effectivityDate: "",
          terminationDate: "",
        },
        clientAddresses: [],
        contactInformations: [],
        contractualInformations: [],
        physicians: [],
        clientSalesAssignment: {},
        feeSchedules: [],
      },
      clientCopy: {},
      isLoading: false,
      isSaving: false,
      isAtPageBottom: false,
    };
  },
  computed: {
    isEditMode() {
      return this.$route.name !== "add-client" ? true : false;
    },
  },
  methods: {
    populateClient(data) {
      const {
        sfdcId,
        payorCode,
        subType,
        exclusion,
        exclusionOther,
        payorName,
        npi,
        tinNumber,
        taxonomyCode,
        effectivityDate,
        terminationDate,
        physicians,
        salesRepId,
        salesRepName,
        salesRepEmail,
        saleRepTerritoryRegion,
        defaultAddress,
        addresses,
        defaultContactInformation,
        contactInformations,
        contractualInformations,
        feeSchedules,
        clientBillType,
      } = data;

      // populate info
      this.client.generalInfo = {
        sfdcId,
        payorCode,
        subTypes: subType ?? [],
        exclusion: {
          id: exclusion ? exclusion.id : null,
          label: exclusion ? exclusion.label : "",
        },
        billType: clientBillType?.enum,
        exclusionOther,
        payorName,
        npi: npi
          ? {
              id: npi.id,
              npiNumber: npi.npiNumber,
            }
          : {
              id: null,
              npiNumber: "",
            },
        tinNumber,
        taxonomy: taxonomyCode,
        effectivityDate: effectivityDate
          ? this.$options.filters.getTimeDate(effectivityDate, "MM/DD/YYYY")
              .zone
          : null,
        terminationDate: terminationDate
          ? this.$options.filters.getTimeDate(terminationDate, "MM/DD/YYYY")
              .zone
          : null,
      };

      // populate physicians
      this.client.physicians =
        physicians && physicians.length
          ? physicians.map((item) => {
              const {
                id,
                physicianId,
                taxonomy,
                firstName,
                middleName,
                lastName,
                npi,
                stateMedicalLicense,
                licenseState,
                licenseStatus,
                defaultAddress,
                defaultPhone,
                defaultEmail,
                phones,
                emails,
                effectivityDate,
                terminationDate,
                archived
              } = item;

              return {
                id,
                uuid: id,
                physicianId,
                taxonomy,
                firstName,
                middleName,
                lastName,
                npi,
                stateMedicalLicense,
                licenseState,
                licenseStatus,
                archived,
                defaultAddress,
                defaultPhone: defaultPhone,
                defaultEmail,
                phones:
                  phones && phones.length
                    ? phones.map((phoneItem) => {
                        var isDefault = false;
                        const { id, phone, areaCode } = phoneItem;

                        if (defaultPhone && defaultPhone.id === id)
                          isDefault = true;

                        return {
                          id,
                          uuid: uuid.v1(),
                          isDefault,
                          phone,
                          areaCode: areaCode,
                        };
                      })
                    : [
                        {
                          id: null,
                          uuid: uuid.v1(),
                          isDefault: true,
                          phone: "",
                          areaCode: "",
                        },
                      ],
                emails:
                  emails && emails.length
                    ? emails.map((emailItem) => {
                        var isDefault = false;
                        const { id, email, emailType } = emailItem;

                        if (defaultEmail && defaultEmail.id === id)
                          isDefault = true;

                        return {
                          id,
                          uuid: uuid.v1(),
                          isDefault,
                          email,
                          emailTypeId: emailType ? emailType.id : null,
                        };
                      })
                    : [
                        {
                          id: null,
                          uuid: uuid.v1(),
                          isDefault: true,
                          email: "",
                          emailTypeId: null,
                        },
                      ],
                effectivityDate: effectivityDate
                  ? this.$options.filters.getTimeDate(
                      effectivityDate,
                      "MM/DD/YYYY"
                    ).zone
                  : null,
                terminationDate: terminationDate
                  ? this.$options.filters.getTimeDate(
                      terminationDate,
                      "MM/DD/YYYY"
                    ).zone
                  : null,
              };
            })
          : [];

      // populate client addresses
      this.client.clientAddresses =
        addresses && addresses.length
          ? addresses.map((item) => {
              let isDefault = false;

              if (defaultAddress) {
                if (item.id === defaultAddress.id) isDefault = true;
              }

              const {
                id,
                line1,
                line2,
                city,
                postalCode,
                stateProvince,
                country,
                addressType,
                mainPhone,
                mainFax,
                archived,
              } = item;

              return {
                id,
                isDefault,
                line1,
                line2,
                city,
                postalCode,
                stateProvince,
                country,
                addressType: addressType
                  ? {
                      id: addressType.id,
                      label: addressType.label,
                      value: addressType.id,
                    }
                  : {
                      id: null,
                      label: "",
                    },
                mainPhone: mainPhone
                  ? {
                      id: mainPhone.id,
                      phone: mainPhone.phone,
                      areaCode: mainPhone.areaCode || "",
                    }
                  : {
                      id: null,
                      phone: "",
                      areaCode: "",
                    },
                mainFax: mainFax
                  ? {
                      id: mainFax.id,
                      phone: mainFax.phone,
                      label: mainFax.label,
                      areaCode: mainFax.areaCode || "",
                    }
                  : {
                      id: null,
                      phone: "",
                      areaCode: "",
                    },
                archived,
              };
            })
          : [];

      // populate client contact informations
      this.client.contactInformations =
        contactInformations && contactInformations.length
          ? contactInformations.map((item) => {
              let isDefault = false;

              if (defaultContactInformation) {
                if (item.id === defaultContactInformation.id) isDefault = true;
              }

              const {
                id,
                contactType,
                firstName,
                middleName,
                lastName,
                nameTitle,
                mainPhone,
                mainFax,
                contactEmail,
                archived,
              } = item;

              return {
                id,
                isDefault,
                contactType: {
                  id: contactType ? contactType.id : null,
                  label: contactType ? contactType.label : "",
                },
                firstName,
                middleName,
                lastName,
                nameTitle,
                mainPhone: mainPhone
                  ? {
                      id: mainPhone.id,
                      phone: mainPhone.phone,
                      areaCode: mainPhone.areaCode || "",
                    }
                  : {
                      id: null,
                      phone: "",
                      label: "",
                      areaCode: "",
                    },
                mainFax: mainFax
                  ? {
                      id: mainFax.id,
                      phone: mainFax.phone,
                      label: mainFax.label,
                      areaCode: mainFax.areaCode,
                    }
                  : {
                      id: null,
                      phone: "",
                      label: "",
                      areaCode: "",
                    },
                contactEmail: contactEmail
                  ? {
                      id: contactEmail.id,
                      email: contactEmail.email,
                      emailTypeId: contactEmail.emailType
                        ? contactEmail.emailType.id
                        : null,
                    }
                  : {
                      id: null,
                      email: "",
                      emailTypeId: null,
                    },
                archived,
              };
            })
          : [];

      // populate contractual informations
      this.client.contractualInformations =
        contractualInformations && contractualInformations.length
          ? contractualInformations.map((item) => {
              const {
                id,
                billingStatementType,
                statementBillingTerm,
                statementBillingTermOther,
                paymentTerm,
                paymentTermOther,
                paymentType,
                paymentTypeOther,
                orderCode,
                testId,
                contractRate,
                feeSchedule,
                preferredMethodType,
                archived,
              } = item;

              return {
                id,
                billingStatementType,
                statementBillingTerm,
                statementBillingTermOther,
                paymentTerm,
                paymentTermOther,
                paymentType,
                paymentTypeOther,
                orderCode,
                methodType: {
                  text: startCase(
                    preferredMethodType?.methodType?.replace("_", " ")
                  ),
                  value: preferredMethodType?.methodType,
                },
                selectedPreferredMethod: {
                  id: preferredMethodType?.selectedId,
                  label: preferredMethodType?.displayLabel,
                },
                test: testId
                  ? {
                      id: testId.id,
                      label: testId.label,
                    }
                  : {
                      id: null,
                      label: "",
                    },
                contractRate,
                feeSchedule,
                archived,
              };
            })
          : [];

      // populate client sales assignment
      this.client.clientSalesAssignment = {
        salesRepresentativeId: salesRepId || "",
        salesRepresentativeName: salesRepName || "",
        salesRepEmail: salesRepEmail || "",
        territoryRegion: saleRepTerritoryRegion || "",
      };

      // populate fee schedule
      this.client.feeSchedules =
        feeSchedules && feeSchedules.length
          ? feeSchedules.map((item) => {
              const {
                id,
                feeName,
                test,
                effectivityDate,
                expirationDate,
                baseRate,
                discountPercentage,
              } = item;

              return {
                id,
                feeName,
                test: test
                  ? {
                      id: test.id,
                      label: test.label,
                    }
                  : {
                      id: null,
                      label: "",
                    },
                effectivityDate,
                expirationDate,
                baseRate,
                discountPercentage,
              };
            })
          : [];

      this.clientCopy = JSON.parse(JSON.stringify(this.client));
    },
    saveSection(name, id = null) {
      this.promptData = { name, action: "save", id};
      this.promptMessage = "Are you sure you want to save the updates?";
      this.promptVisibility = true;
    },
    async doSaveSection(e) {
      const { name, id } = e;

      this.promptVisibility = false;

      switch (name.toUpperCase()) {
        case "GENERALINFO":
          await this.saveGeneralInfo();
          break;
        case "CLIENTPHYSICIAN":
          await this.savePhysician(id);
          break;
        default:
          await this.saveClientSalesAssignment();
          break;
      }

      this.promptData = null;
      this.promptMessage = "";

      this.clientCopy = JSON.parse(JSON.stringify(this.client));
    },
    cancelSectionSaving(name) {
      if (!isEqual(this.clientCopy[name], this.client[name])) {
        this.promptData = { name, action: "cancel" };
        this.promptMessage = "Cancel this activity?";
        this.promptVisibility = true;
      } else {
        this.sectionState[name].isEditing = false;
      }
    },
    doCancelSaving(e) {
      const { name } = e;

      this.copyLocalData(name);
      this.sectionState[name].isEditing = false;

      this.promptData = null;
      this.promptMessage = "";
      this.promptVisibility = false;
    },
    async doPromptAction(e) {
      const { action } = e;
      if (action.toUpperCase() === "SAVE") {
        await this.doSaveSection(e);
      } else {
        this.doCancelSaving(e);
      }
    },
    sectionHasData(name) {
      return (
        Object.keys(this.client[name]).length > 0 || this.client[name].length
      );
    },
    copyLocalData(dataName, copy = false) {
      if (copy) {
        this.clientCopy[dataName] = JSON.parse(
          JSON.stringify(this.client[dataName])
        );
      } else {
        this.client[dataName] = JSON.parse(
          JSON.stringify(this.clientCopy[dataName])
        );
      }
    },
    async saveGeneralInfo() {
      try {
        this.sectionState.generalInfo.isSaving = true;

        const validate =
          await this.$refs.generalInfo.$refs.form.validateAsync();

        if (validate) {
          const {
            payorCode,
            subTypes,
            payorName,
            npi,
            tinNumber,
            taxonomy,
            effectivityDate,
            terminationDate,
            billType,
          } = this.client.generalInfo;

          let params = {
            queries: {
              updatePayorCode: {
                payorCode: {
                  type: "String!",
                  value: payorCode,
                },
              },
              updatePayorName: {
                payorName: {
                  type: "String!",
                  value: payorName,
                },
              },
              updateNpi: {
                npiNumber: {
                  type: "String",
                  value: npi.npiNumber,
                },
              },
              updateTinNumber: {
                tinNumber: {
                  type: "String!",
                  value: tinNumber,
                },
              },
              updateTaxonomyCode: {
                taxonomyCode: {
                  type: "String",
                  value: taxonomy,
                },
              },
            },
            collectionQueries: [
              {
                updateClientBillTypes: {
                  clientBillType: {
                    type: "VbsClientBillTypesEnum!",
                    value: billType,
                  },
                  mutationReturn: ["success", "errors"],
                },
                updateValidityDates: {
                  effectivityDate: {
                    type: "String",
                    value: effectivityDate
                      ? this.$options.filters.getTimeDate(
                          effectivityDate,
                          "YYYY-MM-DD HH:mm:ss",
                          false,
                          null,
                          this.getTimezoneOffset({
                            date: effectivityDate,
                          })
                        ).utc
                      : "",
                    unincludeToFields: true,
                  },
                  terminationDate: {
                    type: "String",
                    value: terminationDate
                      ? this.$options.filters.getTimeDate(
                          terminationDate,
                          "YYYY-MM-DD HH:mm:ss",
                          false,
                          null,
                          {
                            days: 1,
                            ...this.getTimezoneOffset({
                              date: terminationDate,
                              minusSeconds: 1,
                            }),
                          }
                        ).utc
                      : "",
                    unincludeToFields: true,
                  },
                  mutationReturn: ["success", "errors"],
                },
              },
            ],
            variables: {
              payorAccountId: {
                type: "UUID!",
                value: this.client.id,
                unincludeToFields: true,
              },
            },
          };

          if (!isEqual(this.clientCopy.generalInfo.subTypes, subTypes)) {
            params.collectionQueries.push({
              removeSubType: {
                subType: {
                  type: "SubType!",
                  value: this.clientCopy.generalInfo.subTypes[0],
                },
              },
            });

            params.collectionQueries.push({
              addSubType: {
                subType: {
                  type: "SubType!",
                  value: subTypes[0],
                },
              },
            });
          }

          const result = await this.actions.updatePayorAccount(params);

          if (result)
            this.showNotifyMessage({
              message: "Client general information saved.",
              type: "success",
            });
        }
      } catch (err) {
        this.showNotifyMessage({
          message: "Problem has occurred while saving data.",
          type: "danger",
        });
      } finally {
        this.sectionState.generalInfo.isSaving = false;
        this.sectionState.generalInfo.isEditing = false;
      }
    },
    async savePhysician(id){
      try {
        this.sectionState.clientSalesAssignment.isSaving = true;
        
        let params = {
          variables: {
            payorAccountId: {
              type: "UUID!",
              value: this.client.id,
              unincludeToFields: true,
            },
          },
          queries: {},
          collectionQueries:[]
        }

        const physicians = this.client.physicians.filter(({uuid})=> uuid == id);

        await this.handleMutationOnPhysicians(params, physicians);
        const result = await this.actions.updatePayorAccount(params);
        if (result)
        this.showNotifyMessage({
          message: "Physician assignment saved.",
          type: "success",
        });

      } catch (err) {
        this.showNotifyMessage({
          message: "Problem has occurred while saving data.",
          type: "danger",
        });
      } finally {
        this.sectionState.clientPhysician.isSaving = false;
      }
    },
    async saveClientSalesAssignment() {
      try {
        this.sectionState.clientSalesAssignment.isSaving = true;

        const validate =
          await this.$refs.clientSalesAssignment.$refs.form.validateAsync();

        if (validate) {
          const {
            salesRepresentativeId,
            salesRepresentativeName,
            territoryRegion,
          } = this.client.clientSalesAssignment;

          const result = await this.actions.updatePayorAccount({
            variables: {
              payorAccountId: {
                type: "UUID!",
                value: this.client.id,
                unincludeToFields: true,
              },
            },
            queries: {
              updateSalesRepId: {
                salesRepId: {
                  type: "String",
                  value: salesRepresentativeId,
                },
              },
              updateSalesRepName: {
                salesRepName: {
                  type: "String",
                  value: salesRepresentativeName,
                },
              },
              updateSaleRepTerritoryRegion: {
                saleRepTerritoryRegion: {
                  type: "String",
                  value: territoryRegion,
                },
              },
            },
          });

          if (result)
            this.showNotifyMessage({
              message: "Client sales assignment saved.",
              type: "success",
            });

            this.$refs.clientSalesAssignment.setSalesCopyObject(this.client.clientSalesAssignment)
        }
      } catch (err) {
        this.showNotifyMessage({
          message: "Problem has occurred while saving data.",
          type: "danger",
        });
      } finally {
        this.sectionState.clientSalesAssignment.isSaving = false;
        this.sectionState.clientSalesAssignment.isEditing = false;
      }
    },
    handleScroll(event) {
      const scrollableHeight =
        event.target.scrollingElement.offsetHeight - window.innerHeight;
      const footerOffset = 60;
      if (
        window.scrollY >= scrollableHeight - footerOffset &&
        !this.isAtPageBottom
      ) {
        this.isAtPageBottom = true;
      }
      if (
        window.scrollY < scrollableHeight - footerOffset &&
        this.isAtPageBottom
      ) {
        this.isAtPageBottom = false;
      }
    },

    cancelSaveClient() {
      if (!isEqual(this.client, this.clientCopy)) {
        this.cancelModalVisibility = true;
      } else {
        this.cancelSaving();
      }
    },
    cancelSaving() {
      this.$router.push("/system-maintenance/clients-physicians/clients");
    },

    setUpListeners() {
      this.$eventBus.$on("removePreferredMethod", (uuid) => {
        this.setPreferredMethod(uuid);
      });

      this.$eventBus.$on(
        "setPreferredMethod",
        ({ uuid, displayLbl, displayObj }) => {
          this.setPreferredMethod(uuid, true, displayLbl, displayObj);
        }
      );
    },

    setPreferredMethod(
      uuid,
      SET = false,
      displayLbl = null,
      displayObj = null
    ) {
      const { contractualInformations } = this.client;

      let objSelected = null;
      let objPropertyName = null;

      this.client.contractualInformations = [...contractualInformations].map((item) => {
        if(item.selectedPreferredMethod.id == uuid) {
          if (displayObj) {
            if (
              item.methodType.value ===
              METHOD_TYPE_EMAIL
            ) {
              objPropertyName = "email";
            } else {
              objPropertyName = "fax";
            }
          }
  
          if (SET) {
            objSelected = {
              id: uuid,
              label: displayLbl ? displayLbl : displayObj[objPropertyName],
            };
          } else {
            objSelected = {
              id: null,
              label: "",
            };
          }
  
          item.selectedPreferredMethod = objSelected;
          return item
        }
        return item;
      });
    },

    async validateSections() {
      let forms = [
        await this.$refs.generalInfo.validateForm(),
        await this.$refs.clientPhysician.validateForm()
      ];
      return forms.filter((bool) => !bool).length === 0;
    },

    validateTableSections() {
      let tables = [
        this.client.clientAddresses.length !== 0,
        this.client.contactInformations.length !== 0,
        this.client.contractualInformations.length !== 0,
      ];
      return tables.filter((bool) => !bool).length === 0;
    },

    async saveClient(e) {
      try {
        e.preventDefault();

        const validateFormSections = await this.validateSections();

        if (!validateFormSections) {
          this.showNotifyMessage({
            message: "Please fill all the required fields.",
            type: "danger",
          });
          return;
        }
        const validateTableSections = this.validateTableSections();

        if (!validateTableSections) {
          this.showNotifyMessage({
            message:
              "Please fill atleast one each in Address, Contact, Contractual and Physician Assignment",
            type: "danger",
          });
          return;
        }

        const hasSelectedPreferredMethod =
          this.client.contractualInformations.every(
            ({ selectedPreferredMethod }) => selectedPreferredMethod.id
          );
        if (!hasSelectedPreferredMethod) {
          this.showNotifyMessage({
            message:
              "Please add preferred method in your contractual information",
            type: "danger",
          });
          return;
        }

        this.isSaving = true;

        await this.handleMutationOnPayorAccount();
      } catch (err) {
        this.showNotifyMessage({
          message: "Problem has occurred while saving data.",
          type: "danger",
        });
      } finally {
        this.isSaving = false;
      }
    },

    async handleMutationOnPayorAccount() {
      if (!this.client.id) {
        await this.createPayorAccount();
      }
    },

    async createPayorAccount() {
      try {
        const {
          payorCode,
          payorName,
          tinNumber,
          taxonomy,
          effectivityDate,
          terminationDate,
          subTypes,
          npi,
          billType,
        } = this.client.generalInfo;

        const {
          salesRepresentativeId,
          salesRepresentativeName,
          territoryRegion,
        } = this.client.clientSalesAssignment;

        const createNpi = await this.actions.createNpi({
          npiNumber: {
            type: "String!",
            value: npi.npiNumber,
          },
        });

        let params = {
          variables: {
            payorCode: {
              type: "String!",
              value: payorCode,
            },
            payorAccountType: {
              type: "PayorAccountType!",
              value: "CLIENT",
            },
            payorName: {
              type: "String!",
              value: payorName,
            },
            tinNumber: {
              type: "String",
              value: tinNumber,
            },
          },
          queries: {
            addSubType: {
              subType: {
                type: "SubType!",
                value: subTypes[0],
              },
            },
            setNpi: {
              npiId: {
                type: "UUID!",
                value: createNpi?.id,
              },
            },
            updateSalesRepId: {
              salesRepId: {
                type: "String",
                value: salesRepresentativeId,
              },
            },
            updateSalesRepName: {
              salesRepName: {
                type: "String",
                value: salesRepresentativeName,
              },
            },
            updateSaleRepTerritoryRegion: {
              saleRepTerritoryRegion: {
                type: "String",
                value: territoryRegion,
              },
            },
          },
          collectionQueries: [],
        };

        if (taxonomy.length)
          params.queries["updateTaxonomyCode"] = {
            taxonomyCode: {
              type: "String",
              value: taxonomy,
            },
          };

        params.collectionQueries.push({
          updateClientBillTypes: {
            clientBillType: {
              type: "VbsClientBillTypesEnum!",
              value: billType,
            },
            mutationReturn: ["success", "errors"],
          },
        });

        if (effectivityDate.length || terminationDate.length) {
          params.collectionQueries.push({
            updateValidityDates: {
              effectivityDate: {
                type: "String",
                value: effectivityDate
                  ? this.$options.filters.getTimeDate(
                      effectivityDate,
                      "YYYY-MM-DD HH:mm:ss",
                      false,
                      null,
                      this.getTimezoneOffset({
                        date: effectivityDate,
                      })
                    ).utc
                  : "",
                unincludeToFields: true,
              },
              terminationDate: {
                type: "String",
                value: terminationDate
                  ? this.$options.filters.getTimeDate(
                      terminationDate,
                      "YYYY-MM-DD HH:mm:ss",
                      false,
                      null,
                      {
                        days: 1,
                        ...this.getTimezoneOffset({
                          date: terminationDate,
                          minusSeconds: 1,
                        }),
                      }
                    ).utc
                  : "",
                unincludeToFields: true,
              },
              mutationReturn: ["success", "errors"],
            },
          });
        }

        await this.handleMutationOnAddressInfo(params);
        await this.handleMutationOnContactInformations(params);
        await this.handleMutationOnClientContractualInfo(params);
        await this.handleMutationOnPhysicians(params);

        const result = await this.actions.createPayorAccount(params);

        this.showNotifyMessage({
          message: "Client saved.",
          type: "success",
        });

        if (result)
          this.$router.push("/system-maintenance/clients-physicians/clients");
      } catch (err) {
        throw Error(err);
      }
    },

    async handleMutationOnAddressInfo(params) {
      try {
        const { clientAddresses } = this.client;

        for (let [index, address] of clientAddresses.entries()) {
          if (address.id) {
            params.collectionQueries.push({
              updateAddress: {
                addressId: {
                  type: "UUID!",
                  value: address.id,
                },
                line1: {
                  type: "String!",
                  value: address.line1,
                },
                line2: {
                  type: "String",
                  value: address.line2,
                },
                city: {
                  type: "String",
                  value: address.city,
                },
                postalCode: {
                  type: "String",
                  value: address.postalCode,
                },
                country: {
                  type: "String",
                  value: address.country,
                },
                stateProvince: {
                  type: "String",
                  value: address.stateProvince,
                },
                addressTypeId: {
                  type: "UUID!",
                  value: address.addressType.id,
                },
              },
            });
          } else {
            const { mainPhone, mainFax } = address;

            const createAddressMainPhone = await this.handleCreatePhone(
              mainPhone.phone,
              mainPhone.areaCode
            );
            const createAddressMainFax = await this.handleCreatePhone(
              mainFax.phone,
              mainFax.areaCode
            );

            mainPhone.id = createAddressMainPhone.id;
            mainFax.id = createAddressMainFax.id;

            const createAddress = await this.actions.createAddress({
              params: {
                line1: {
                  type: "String!",
                  value: address.line1,
                },
                line2: {
                  type: "String",
                  value: address.line2,
                },
                city: {
                  type: "String",
                  value: address.city,
                },
                postalCode: {
                  type: "String",
                  value: address.postalCode,
                },
                country: {
                  type: "String",
                  value: address.country,
                },
                stateProvince: {
                  type: "String",
                  value: address.stateProvince,
                },
              },
              queries: {
                setAddressType: {
                  addressTypeId: {
                    type: "UUID!",
                    value: address.addressType.value,
                  },
                },
              },
              collectionQueries: [
                {
                  setMainPhone: {
                    phoneId: {
                      type: "UUID!",
                      value: mainPhone.id,
                    },
                  },
                  setMainFax: {
                    phoneId: {
                      type: "UUID!",
                      value: mainFax.id,
                    },
                  },
                },
              ],
            });

            address.id = createAddress.id;
            this.client.clientAddresses[index].id = createAddress.id;

            if (address.isDefault)
              params.queries["setDefaultAddress"] = {
                addressId: {
                  type: "UUID!",
                  value: address.id,
                },
              };

            params.collectionQueries.push({
              addAddress: {
                addressId: {
                  type: "UUID!",
                  value: address.id,
                },
              },
            });
          }
        }
      } catch (err) {
        throw Error(err);
      }
    },

    async handleMutationOnContactInformations(params) {
      try {
        const { contactInformations } = this.client;

        for (let [index, contactInformation] of contactInformations.entries()) {
          const { mainPhone, mainFax, contactEmail } = contactInformation;

          if (contactInformation.id) {
            const ciParam = {
              variables: {
                id: {
                  type: "UUID",
                  value: contactInformation.id,
                },
              },
              queries: {
                updateName: {
                  firstName: {
                    type: "String",
                    value: contactInformation.firstName,
                  },
                  middleName: {
                    type: "String",
                    value: contactInformation.middleName,
                  },
                  lastName: {
                    type: "String",
                    value: contactInformation.lastName,
                  },
                },
                setNameTitle: {
                  nameTitle: {
                    type: "NameTitle!",
                    value: contactInformation.nameTitle,
                  },
                },
                updatePhone: {
                  phoneId: {
                    type: "UUID!",
                    value: mainPhone.id,
                  },
                  phone: {
                    type: "String",
                    value: this.sanitizePhoneString(mainPhone.phone),
                  },
                  areaCode: {
                    type: "String",
                    value: this.sanitizePhoneString(mainPhone.areaCode),
                  },
                },
                updateFax: {
                  phoneId: {
                    type: "UUID!",
                    value: mainFax.id,
                  },
                  phone: {
                    type: "String",
                    value: this.sanitizePhoneString(mainFax.phone),
                  },
                  areaCode: {
                    type: "String",
                    value: this.sanitizePhoneString(mainFax.areaCode),
                  },
                },
                updateEmail: {
                  emailId: {
                    type: "UUID!",
                    value: contactEmail.id,
                  },
                  email: {
                    type: "String",
                    value: contactEmail.email,
                  },
                  emailTypeId: contactEmail.emailTypeId,
                },
              },
            };

            await this.actions.updateContactInformation(ciParam);
          } else {
            const createMainPhone = await this.handleCreatePhone(
              mainPhone.phone,
              mainPhone.areaCode
            );
            const createMainFax = await this.handleCreatePhone(
              mainFax.phone,
              mainFax.areaCode
            );
            const createContactEmail = await this.handleCreateEmail(
              contactEmail.email,
              contactEmail.emailTypeId
            );

            let ciParam = {
              variables: {
                firstName: {
                  type: "String!",
                  value: contactInformation.firstName,
                },
                lastName: {
                  type: "String!",
                  value: contactInformation.lastName,
                },
                nameTitle: {
                  type: "String",
                  value: contactInformation.nameTitle,
                },
              },
              queries: {
                setMainPhone: {
                  phoneId: {
                    type: "UUID!",
                    value: createMainPhone.id,
                  },
                },
                setEmail: {
                  emailId: {
                    type: "UUID!",
                    value: createContactEmail.id,
                  },
                },
              },
              collectionQueries: [
                {
                  setMainFax: {
                    phoneId: {
                      type: "UUID!",
                      value: createMainFax.id,
                    },
                  },
                },
              ],
            };

            if (contactInformation.middleName.length)
              ciParam.variables["middleName"] = {
                type: "String",
                value: contactInformation.middleName,
              };

            const createContactInformation =
              await this.actions.createContactInformation(ciParam);

            mainPhone.id = createMainPhone.id;
            mainFax.id = createMainFax.id;
            contactEmail.id = createContactEmail.id;
            contactInformation.id = createContactInformation.id;

            this.client.contactInformations[index].mainFax.id =
              createMainFax.id;
            this.client.contactInformations[index].contactEmail.id =
              createContactEmail.id;

            if (contactInformation.isDefault)
              params.queries["setDefaultContactInformation"] = {
                contactInformationId: {
                  type: "UUID!",
                  value: contactInformation.id,
                },
              };

            params.collectionQueries.push({
              addContactInformation: {
                contactInformationId: {
                  type: "UUID!",
                  value: contactInformation.id,
                },
              },
            });
          }
        }
      } catch (err) {
        throw Error(err);
      }
    },

    getPreferredMethodByUUID(methodType, findUUID) {
      const { clientAddresses, contactInformations } = this.client;

      if (methodType === METHOD_TYPE_ADDRESS) {
        return clientAddresses.find(({ uuid }) => uuid == findUUID)?.id;
      } else if (methodType === METHOD_TYPE_FAX) {
        return contactInformations.find(({ uuid }) => uuid === findUUID)
          ?.mainFax?.id;
      } else if (methodType === METHOD_TYPE_EMAIL) {
        return contactInformations.find(({ uuid }) => uuid === findUUID)
          ?.contactEmail?.id;
      }
    },

    async handleMutationOnClientContractualInfo(params) {
      try {
        const { contractualInformations } = this.client;

        for (let contractualInfo of contractualInformations) {
          const {
            billingStatementType,
            statementBillingTerm,
            // orderCode,
            // test,
            // contractRate,

            methodType,
            selectedPreferredMethod,
            feeSchedule,
          } = contractualInfo;

          if (contractualInfo.id) {
            params.collectionQueries.push({
              updateContractualInformation: {
                billingClientContractualInformationId: {
                  type: "UUID!",
                  value: contractualInfo.id,
                },
                billingStatementType: {
                  type: "BillingStatementType",
                  value: billingStatementType,
                },
                statementBillingTerm: {
                  type: "StatementBillingTerm",
                  value: statementBillingTerm,
                },
                feeSchedule: {
                  type: "String",
                  value: feeSchedule,
                },
              },
            });
          } else {
            let findType = "";
            const currType = methodType.text.toUpperCase();

            if (currType.includes(METHOD_TYPE_ADDRESS)) {
              findType = METHOD_TYPE_ADDRESS;
            } else if (currType.includes(METHOD_TYPE_EMAIL)) {
              findType = METHOD_TYPE_EMAIL;
            } else {
              findType = METHOD_TYPE_FAX;
            }

            const preferredId = this.getPreferredMethodByUUID(
              findType,
              selectedPreferredMethod.id
            );

            let paramCreateBillingInput = {
              preferredMethodType: {
                methodType: methodType.value,
                selectedId: preferredId,
              },
              statementBillingTerm: statementBillingTerm,
            };

            const variables = {
              input: {
                type: "BillingClientContractualInformationInput",
                value: paramCreateBillingInput,
                unincludeToFields: true,
              },
            };

            const queries = {
              updateBillingStatementType: {
                billingStatementType: {
                  type: "BillingStatementType",
                  value: billingStatementType,
                },
              },
              updateFeeSchedule: {
                feeSchedule: {
                  type: "String",
                  value: feeSchedule,
                },
              },
            };

            const createBCCI = await this.actions.createBCCI({
              variables,
              queries,
            });

            contractualInfo.id = createBCCI.id;

            params.collectionQueries.push({
              addContractualInformation: {
                billingClientContractualInformationId: {
                  type: "UUID!",
                  value: contractualInfo.id,
                },
              },
            });
          }
        }
      } catch (err) {
        throw Error(err);
      }
    },
    async handleMutationOnPhysicians(params, selectedPhysicians = null) {
      try {
        const { physicians } = this.client;
        
        const listOfPhysician = !selectedPhysicians ? [...physicians] : [...selectedPhysicians]

        for (let physician of listOfPhysician) {
          const { phones, emails, npi, stateMedicalLicense } = physician;

          if (physician.id) {
            params.collectionQueries.push({
              addPhysician: {
                physicianId: {
                  type: "UUID!",
                  value: physician.id,
                },
              },
            });
          } else {
            const createNpi = await this.actions.createNpi({
              npiNumber: {
                type: "String!",
                value: npi.npiNumber,
              },
            });

            physician.npi.id = createNpi.id;

            const physicianParams = {
              variables: {
                firstName: {
                  type: "String!",
                  value: physician.firstName,
                },
                lastName: {
                  type: "String!",
                  value: physician.lastName,
                },
              },
              queries: {
                updatePhysicianType: {
                  physicianType: {
                    type: "String",
                    value: physician.physicianType,
                  },
                },
                setNpi: {
                  npiId: {
                    type: "UUID!",
                    value: physician.npi.id,
                  },
                },
                updateTaxonomy: {
                  taxonomy: {
                    type: "String",
                    value: physician.taxonomy,
                  },
                },
              },
              collectionQueries: [
                {
                  updateValidityDates: {
                    effectivityDate: {
                      type: "String",
                      value: physician.effectivityDate
                        ? this.$options.filters.getTimeDate(
                            physician.effectivityDate,
                            "YYYY-MM-DD HH:mm:ss",
                            false,
                            null,
                            this.getTimezoneOffset({
                              date: physician.effectivityDate,
                            })
                          ).utc
                        : "",
                      unincludeToFields: true,
                    },
                    terminationDate: {
                      type: "String",
                      value: physician.terminationDate
                        ? this.$options.filters.getTimeDate(
                            physician.terminationDate,
                            "YYYY-MM-DD HH:mm:ss",
                            false,
                            null,
                            {
                              days: 1,
                              ...this.getTimezoneOffset({
                                date: physician.terminationDate,
                                minusSeconds: 1,
                              }),
                            }
                          ).utc
                        : "",
                      unincludeToFields: true,
                    },
                    mutationReturn: ["success", "errors"],
                  },
                },
              ],
            };

            for (let phone of phones) {
              const createPhone = await this.handleCreatePhone(
                phone.phone,
                phone.areaCode
              );

              phone.id = createPhone.id;

              physicianParams.collectionQueries.push({
                addPhone: {
                  phoneId: {
                    type: "UUID!",
                    value: phone.id,
                  },
                },
              });

              if (phone.isDefault)
                physicianParams.queries["setDefaultPhone"] = {
                  phoneId: {
                    type: "UUID!",
                    value: phone.id,
                  },
                };
            }

            for (let email of emails) {
              const createEmail = await this.handleCreateEmail(
                email.email,
                email.emailTypeId
              );

              email.id = createEmail.id;

              physicianParams.collectionQueries.push({
                addEmail: {
                  emailId: {
                    type: "UUID!",
                    value: email.id,
                  },
                },
              });

              if (email.isDefault)
                physicianParams.queries["setDefaultEmail"] = {
                  emailId: {
                    type: "UUID!",
                    value: email.id,
                  },
                };
            }

            if (physician.middleName)
              physicianParams.variables["middleName"] = {
                type: "String",
                value: physician.middleName,
              };

            if (physician.licenseState.length)
              physicianParams.queries["updateLicenseState"] = {
                licenseState: {
                  type: "String",
                  value: physician.licenseState,
                },
              };

            if (physician.licenseStatus.length)
              physicianParams.queries["updateLicenseStatus"] = {
                licenseStatus: {
                  type: "LicenseStatus",
                  value: physician.licenseStatus,
                },
              };

            if (stateMedicalLicense.licenseNumber) {
              const createSML = await this.actions.createStateMedicalLicense({
                licenseNumber: {
                  type: "String!",
                  value: stateMedicalLicense.licenseNumber,
                },
              });
              physician.stateMedicalLicense.id = createSML.id;

              physicianParams.queries["setStateMedicalLicense"] = {
                stateMedicalId: {
                  type: "UUID!",
                  value: physician.stateMedicalLicense.id,
                },
              };
            }

            const createPhysician = await this.actions.createPhysician(
              physicianParams
            );

            physician.id = createPhysician.id;

            if (physician.isDefault)
              params.queries["setDefaultPhysician"] = {
                physicianId: {
                  type: "UUID!",
                  value: physician.id,
                },
              };

            params.collectionQueries.push({
              addPhysician: {
                physicianId: {
                  type: "UUID!",
                  value: physician.id,
                },
              },
            });
          }
        }
      } catch (err) {
        throw Error(err);
      }
    },
    async handleCreatePhone(phone, areaCode) {
      try {
        let params = {
          phone: {
            type: "String!",
            value: this.sanitizePhoneString(phone),
            unincludeToFields: true,
          },
          areaCode: {
            type: "String",
            value: this.sanitizePhoneString(areaCode),
            unincludeToFields: true,
          },
        };

        const result = await this.actions.createPhone(params);

        return result;
      } catch (err) {
        throw Error(err);
      }
    },
    async handleCreateEmail(email, emailTypeId = null) {
      try {
        let params = {
          variables: {
            email: {
              type: "String!",
              value: email,
            },
          },
          queries: {},
        };

        if (emailTypeId)
          params.queries["setEmailType"] = {
            emailTypeId: {
              type: "UUID!",
              value: emailTypeId,
            },
          };

        const result = await this.actions.createEmail(params);

        return result;
      } catch (err) {
        throw Error(err);
      }
    },
  },
  async created() {
    try {
      this.setUpListeners();
      this.clientCopy = JSON.parse(JSON.stringify(this.client));

      if (this.$route.params.id) {
        this.isLoading = true;
        this.client.id = this.$route.params.id;

        const result = await this.actions.getPayorAccount({
          limitData: [
            "sfdcId",
            "payorCode",
            "subType",
            "payorName",
            this.buildSubQuery("exclusion", ["label"]),
            this.buildSubQuery("clientBillType", ["label", "enum"], false),
            "exclusionOther",
            this.buildSubQuery("npi", ["npiNumber"]),
            "tinNumber",
            "taxonomyCode",
            "effectivityDate",
            "terminationDate",
            this.buildSubQuery("defaultAddress", ["archived"]),
            this.buildSubQuery("addresses(archive: true)", [
              "line1",
              "line2",
              "city",
              "postalCode",
              "stateProvince",
              "country",
              this.buildSubQuery("addressType", ["label"]),
              this.buildSubQuery("mainPhone", ["phone", "areaCode"]),
              this.buildSubQuery("mainFax", ["phone", "areaCode"]),
              "archived",
            ]),
            this.buildSubQuery("defaultContactInformation", []),
            this.buildSubQuery("contactInformations(archive: true)", [
              this.buildSubQuery("contactType", ["label"]),
              "firstName",
              "middleName",
              "lastName",
              "nameTitle",
              this.buildSubQuery("mainPhone", ["phone", "areaCode"]),
              this.buildSubQuery("mainFax", ["phone", "areaCode"]),
              this.buildSubQuery("contactEmail", [
                "email",
                this.buildSubQuery("emailType", []),
              ]),
              "archived",
            ]),
            this.buildSubQuery("contractualInformations(archive: true)", [
              "billingStatementType",
              "statementBillingTerm",
              "statementBillingTermOther",
              this.buildSubQuery("paymentTerm", ["label"]),
              "paymentTermOther",
              this.buildSubQuery("paymentType", ["label"]),
              "paymentTypeOther",
              "orderCode",
              this.buildSubQuery("testId", ["label"]),
              "contractRate",
              "feeSchedule",
              this.buildSubQuery(
                "preferredMethodType",
                ["methodType", "selectedId", "displayLabel"],
                false
              ),
              "archived",
            ]),
            this.buildSubQuery("physicians", [
              "physicianId",
              "taxonomy",
              "firstName",
              "middleName",
              "lastName",
              this.buildSubQuery("npi", ["npiNumber"]),
              this.buildSubQuery("stateMedicalLicense", ["licenseNumber"]),
              "licenseState",
              "licenseStatus",
              this.buildSubQuery("defaultPhone", ["phone", "areaCode"]),
              this.buildSubQuery("phones", ["phone", "areaCode"]),
              this.buildSubQuery("defaultEmail", ["email"]),
              this.buildSubQuery("emails", [
                "email",
                this.buildSubQuery("emailType", []),
              ]),
              this.buildSubQuery("defaultAddress", [
                "line1",
                "line2",
                "city",
                "stateProvince",
                "postalCode",
                "country",
              ]),
              "effectivityDate",
              "terminationDate",
              "archived",
            ]),
            "salesRepId",
            "salesRepName",
            "salesRepEmail",
            "saleRepTerritoryRegion",
            this.buildSubQuery("feeSchedules", [
              "feeName",
              this.buildSubQuery("test", ["label"]),
              "effectivityDate",
              "expirationDate",
              "baseRate",
              "discountPercentage",
            ]),
          ],
          variables: {
            property: {
              id: {
                type: "UUID!",
                value: this.client.id,
              },
            },
          },
        });

        if (result) this.populateClient(result);
        this.isLoading = false;
      }
      await this.actions.getAllAddressTypes({
        filter: {
          forBilling: true,
        },
      });
    } catch (err) {
      this.showNotifyMessage({
        message: "Unable to fetch data.",
        type: "danger",
      });
    }
  },

  mounted() {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
