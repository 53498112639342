import isEqual from "lodash/isEqual";

import ClientAccountsActions from "modules/actions/client-accounts-actions";

export default {
  name: "ClientAddressModal",
  props: {
    existingAddress: {
      required: false,
      type: Object,
    },
    clientId: {
      type: String,
    },
    editMode: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      actions: new ClientAccountsActions(),
      isLoading: false,
      isDefaultInitially: false,
      addressTypes: [],
      prompt: {
        visibility: false,
        message: "",
        action: "",
      },
      rules: {
        required: (val) => this.requiredField(val),
        requiredAddressType: (val) => {
          if (val instanceof Object) {
            val = val.value;
          }
          return this.requiredField(val);
        },
      },
      address: {
        id: null,
        uuid: "",
        isDefault: true,
        line1: "",
        line2: "",
        city: "",
        postalCode: "",
        stateProvince: "",
        country: "",
        addressType: {
          id: null,
          label: "",
        },
        mainPhone: {
          id: null,
          phone: "",
          areaCode: "",
        },
        mainFax: {
          id: null,
          phone: "",
          areaCode: "",
        },
      },
    };
  },
  computed: {
    disableField() {
      return this.isLoading;
    },
    headerLabel() {
      if (!this.editMode) return "Client Address Details";

      return (this.existingAddress ? "Edit" : "Add") + " Client Address";
    },

    btnLabel() {
      return this.existingAddress ? "Save" : "Add";
    },
  },
  methods: {
    requiredField(val) {
      return !["", null, undefined].includes(val) || "This field is required.";
    },
    async getAddressTypes() {
      try {
        if (!this.addressTypes.length) {
          this.addressTypes = [
            { label: "Fetching address types...", disabled: true },
          ];

          const result = await this.actions.getAllAddressTypes({
            filter: {
              forBilling: true,
            },
          });

          if (result) {
            this.addressTypes = result.map((item) => {
              return {
                id: item.id,
                label: item.label,
              };
            });
          } else {
            this.showNotifyMessage({
              type: "danger",
              message: "Unable to fetch data.",
            });
          }
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Unable to fetch data.",
        });
      }
    },
    async doSave() {
      try {
        if (!this.clientId) {
          throw ReferenceError("Client Id does not exist");
        }

        this.isLoading = true;

        const validate = await this.validateForm();

        if (validate) {
          const {
            mainPhone,
            mainFax,
            isDefault,
            line1,
            line2,
            city,
            postalCode,
            stateProvince,
            country,
            addressType,
          } = this.address;

          if (this.address.id) {
            let collectionQueries = [];

            if (mainFax.id) {
              collectionQueries.push({
                updateFax: {
                  phoneId: {
                    type: "UUID!",
                    value: mainFax.id,
                  },
                  phone: {
                    type: "String",
                    value: this.sanitizePhoneString(mainFax.phone),
                  },
                  areaCode: {
                    type: "String",
                    value: this.sanitizePhoneString(mainFax.areaCode),
                  },
                },
              });
            } else {
              const createMainFax = await this.actions.createPhone({
                phone: {
                  type: "String!",
                  value: this.sanitizePhoneString(mainFax.phone),
                  unincludeToFields: true,
                },
                areaCode: {
                  type: "String",
                  value: this.sanitizePhoneString(mainFax.areaCode),
                  unincludeToFields: true,
                },
              });

              if (createMainFax) mainFax.id = createMainFax.id;

              collectionQueries.push({
                setMainFax: {
                  phoneId: {
                    type: "UUID!",
                    value: mainFax.id,
                  },
                },
              });
            }

            if (mainPhone.id) {
              collectionQueries.push({
                updatePhone: {
                  phoneId: {
                    type: "UUID!",
                    value: mainPhone.id,
                  },
                  phone: {
                    type: "String",
                    value: this.sanitizePhoneString(mainPhone.phone),
                  },
                  areaCode: {
                    type: "String",
                    value: this.sanitizePhoneString(mainPhone.areaCode),
                  },
                },
              });
            } else {
              const createMainPhone = await this.actions.createPhone({
                phone: {
                  type: "String!",
                  value: this.sanitizePhoneString(mainPhone.phone),
                  unincludeToFields: true,
                },
                areaCode: {
                  type: "String",
                  value: this.sanitizePhoneString(mainPhone.areaCode),
                  unincludeToFields: true,
                },
              });
              if (createMainPhone) mainPhone.id = createMainPhone.id;

              collectionQueries.push({
                setMainPhone: {
                  phoneId: {
                    type: "UUID!",
                    value: mainPhone.id,
                  },
                },
              });
            }

            await this.actions.updateAddress({
              variables: {
                addressId: {
                  type: "UUID!",
                  value: this.address.id,
                  unincludeToFields: true,
                },
              },
              queries: {
                setAddressType: {
                  addressTypeId: {
                    type: "UUID!",
                    value: addressType.value,
                  },
                },
              },
              collectionQueries: collectionQueries,
            });

            let params = {
              variables: {
                payorAccountId: {
                  type: "UUID!",
                  value: this.clientId,
                  unincludeToFields: true,
                },
              },
              queries: {
                updateAddress: {
                  addressId: {
                    type: "UUID!",
                    value: this.address.id,
                  },
                  line1: {
                    type: "String",
                    value: line1,
                  },
                  line2: {
                    type: "String",
                    value: line2,
                  },
                  city: {
                    type: "String",
                    value: city,
                  },
                  postalCode: {
                    type: "String",
                    value: postalCode,
                  },
                  country: {
                    type: "String",
                    value: country,
                  },
                  stateProvince: {
                    type: "String",
                    value: stateProvince,
                  },
                },
              },
            };

            if (isDefault)
              params.queries["setDefaultAddress"] = {
                addressId: {
                  type: "UUID!",
                  value: this.address.id,
                },
              };

            await this.actions.updatePayorAccount(params);
          } else {
            const createMainPhone = await this.actions.createPhone({
              phone: {
                type: "String!",
                value: this.sanitizePhoneString(mainPhone.phone),
                unincludeToFields: true,
              },
              areaCode: {
                type: "String",
                value: this.sanitizePhoneString(mainPhone.areaCode),
                unincludeToFields: true,
              },
            });
            const createMainFax = await this.actions.createPhone({
              phone: {
                type: "String!",
                value: this.sanitizePhoneString(mainFax.phone),
                unincludeToFields: true,
              },
              areaCode: {
                type: "String",
                value: this.sanitizePhoneString(mainFax.areaCode),
                unincludeToFields: true,
              },
            });

            if (createMainPhone) mainPhone.id = createMainPhone.id;
            if (createMainFax) mainFax.id = createMainFax.id;

            const createAddress = await this.actions.createAddress({
              params: {
                line1: {
                  type: "String!",
                  value: line1,
                },
                line2: {
                  type: "String",
                  value: line2,
                },
                city: {
                  type: "String",
                  value: city,
                },
                postalCode: {
                  type: "String",
                  value: postalCode,
                },
                country: {
                  type: "String",
                  value: country,
                },
                stateProvince: {
                  type: "String",
                  value: stateProvince,
                },
              },
              queries: {
                setAddressType: {
                  addressTypeId: {
                    type: "UUID!",
                    value: addressType.value,
                  },
                },
              },
              collectionQueries: [
                {
                  setMainPhone: {
                    phoneId: {
                      type: "UUID!",
                      value: mainPhone.id,
                    },
                  },
                },
                {
                  setMainFax: {
                    phoneId: {
                      type: "UUID!",
                      value: mainFax.id,
                    },
                  },
                },
              ],
            });

            if (createAddress) this.address.id = createAddress.id;

            let params = {
              variables: {
                payorAccountId: {
                  type: "UUID!",
                  value: this.clientId,
                  unincludeToFields: true,
                },
              },
              collectionQueries: [
                {
                  addAddress: {
                    addressId: {
                      type: "UUID!",
                      value: this.address.id,
                    },
                  },
                },
              ],
            };

            if (isDefault)
              params.collectionQueries.push({
                setDefaultAddress: {
                  addressId: {
                    type: "UUID!",
                    value: this.address.id,
                  },
                },
              });

            await this.actions.updatePayorAccount(params);
          }

          this.$emit("doSave");
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Problem has occurred while saving data.",
        });
      } finally {
        this.isLoading = false;
      }
    },
    doCancel() {
      this.$emit("doCancel");
    },
    async validateForm() {
      const validate = [
        await this.$refs.form.validateAsync(),
        await this.$refs.mainPhone.$refs.form.validateAsync(),
        await this.$refs.mainFax.$refs.form.validateAsync(),
        await this.$refs.clientAddress.$refs.form.validateAsync(),
      ];
      return validate.filter((bool) => !bool).length === 0;
    },
    cancelSaving() {
      if (
        this.existingAddress &&
        !isEqual(this.address, this.existingAddress)
      ) {
        var hasUnEqual = false;

        for (let key of Object.keys(this.address)) {
          if (
            key.toUpperCase() === "MAINPHONE" ||
            key.toUpperCase() === "MAINFAX"
          ) {
            if (
              (this.address[key].areaCode &&
                this.sanitizePhoneString(this.address[key].areaCode) !==
                  this.existingAddress[key].areaCode) ||
              this.sanitizePhoneString(this.address[key].phone) !==
                this.existingAddress[key].phone
            ) {
              hasUnEqual = true;
              break;
            }
          } else {
            if (!isEqual(this.address[key], this.existingAddress[key])) {
              hasUnEqual = true;
              break;
            }
          }
        }

        if (hasUnEqual) {
          this.promptAction("doCancel", "Cancel this activity?");
        } else {
          this.doCancel();
        }
      } else {
        this.doCancel();
      }
    },
    async saveAddress() {
      if (this.address.id) {
        this.promptAction(
          "doSave",
          "Are you sure you want to save the updates?"
        );
      } else {
        if (!this.editMode) {
          const validate = await this.validateForm();
          if (validate) this.$emit("addAddress", this.address);
        } else {
          this.doSave();
        }
      }
    },
    promptAction(action, message) {
      this.prompt.action = action;
      this.prompt.message = message;
      this.prompt.visibility = true;
    },
    async doPromptAction(action) {
      this.prompt.visibility = false;

      if (action.toUpperCase() === "DOCANCEL") {
        this[action]();
      } else {
        await this[action]();
      }
    },
  },
  async created() {
    try {
      this.isLoading = true;

      if (this.existingAddress) {
        this.address = JSON.parse(JSON.stringify(this.existingAddress));
        this.isDefaultInitially = this.address.isDefault;
        await this.getAddressTypes();
      }
    } catch (err) {
      this.showNotifyMessage({
        type: "danger",
        message: "Unable to fetch data.",
      });
    } finally {
      this.isLoading = false;
    }
  },
};
