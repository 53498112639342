import { uuid } from "vue-uuid";

import actions from "modules/actions/client-accounts-actions";

import PhysicianModal from "modules/main/ClientsPhysicians/Clients/modals/PhysicianModal";
import ClientPhysicianModal from "modules/main/ClientsPhysicians/Clients/modals/ClientPhysicianModal";

export default {
  name: "ClientPhysician",
  components: {
    PhysicianModal,
    ClientPhysicianModal,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    payorAccountNpi: {
      type: String,
      required: true,
    },
    editMode: {
      required: false,
      type: Boolean,
    },
    clientId: {
      required: false,
    },
    isSaving: {
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {
      actions: new actions(),
      licenseStatuses: [],
      menu1: [],
      menu2: [],
      date1: "",
      date2: "",
      physicianModalVisibility: false,
      addEditPhysicianModalVisibility: false,
      isLoading: false,
      selectedPhysicianObj: null,
      promptData: null,
      promptVisibility: false,
      promptMessage: "",
      physicianModalIndex: 0,
      emailTypes: [],
      defaultPhysician: "",
      expandedRow: [],

      assignedPhysician: [],

      rules: {
        required: (val) => {
          if (typeof val === "object" && val !== null)
            return (
              (val && val.id && val.id !== null) || "This field is required"
            );

          return (
            (val !== null && val.length !== 0) || "This field is required."
          );
        },
        validateNpi: async (val, uuid, physicianNpi) =>
          (await this.doValidateNpiNumber(val, uuid, physicianNpi)) ||
          "This field is required.",
        validateLicenseNumber: async (val, physicianId) =>
          (await this.doValidateLicenseNumber(val, physicianId)) ||
          "This field is required.",
        validateEmail: (val) =>
          this.validateEmail(val) || "Invalid email format.",
        validDate: (val) => this.isValidDate(val) || "Invalid date format.",
        validTerminationDate: (val, effectivityDate) => {
          const terminationDate = new Date(val);
          const effectDate = new Date(effectivityDate);

          if (effectivityDate === null || !effectivityDate.length) return true;

          return (
            terminationDate >= effectDate ||
            "Termination date must not be less than the effective date."
          );
        },
      },
      columns: [
        {
          text: "Physician ID",
          value: "physicianId",
          sortable: false,
        },
        {
          text: "Physician Name",
          value: "physicianName",
          sortable: false,
        },
        {
          text: "Taxonomy Number",
          value: "taxonomy",
          sortable: false,
        },
        {
          text: "Physician NPI",
          value: "physicianNpi",
          sortable: false,
        },
        {
          text: "Physician License",
          value: "licenseNumber",
          sortable: false,
        },
        {
          text: "License State",
          value: "licenseState",
          sortable: false,
        },
        {
          text: "License Status",
          align: "center",
          value: "licenseStatus",
          sortable: false,
        },
        {
          text: "Effective Date",
          value: "effectivityDate",
          sortable: false,
        },
        {
          text: "Termination Date",
          value: "terminationDate",
          sortable: false,
        },
        {
          text: "HQ Address Country",
          value: "country",
          sortable: false,
        },
        {
          text: "HQ Address 1",
          value: "line1",
          sortable: false,
        },
        {
          text: "HQ Address 2",
          value: "line2",
          sortable: false,
        },
        {
          text: "HQ Address City",
          value: "city",
          sortable: false,
        },
        {
          text: "HQ Address State",
          value: "stateProvince",
          sortable: false,
        },
        {
          text: "HQ Address Postal Code",
          value: "postalCode",
          sortable: false,
        },
        {
          text: "Status",
          align: "center",
          value: "status",
          sortable: false,
        },
        {
          text: "Action",
          value: "action",
          align: "center",
          sortable: false,
        },
      ],
      footerProps: {
        showFirstLastPage: true,
        "items-per-page-options": [5, 10, 15, 20],
      },
      previewColumn: [
        {
          title: "Physician ID",
          value: "physicianId",
        },
        {
          title: "Physician Name",
          value: "physicianName",
        },
        {
          title: "Physician NPI",
          value: "physicianNpi",
        },
        {
          title: "Physician License",
          value: "licenseNumber",
        },
        {
          title: "Taxonomy Number",
          value: "taxonomy",
        },
        {
          title: "License Status",
          value: "licenseStatus",
        },
      ],
      panels: [],
    };
  },
  computed: {
    __Physicians: {
      get() {
        return this.data;
      },
      set(value) {
        this.$emit("update:data", value);
      },
    },

    linkedPhysicians() {
      return this.data.map((item) => item.id);
    },

    getPhysicianItems() {
      return this.__Physicians.map((item) => {
        const {
          id,
          uuid,
          effectivityDate,
          terminationDate,
          licenseState,
          licenseStatus,
          npi,
          physicianId,
          defaultAddress,
          taxonomy,
          stateMedicalLicense,
          archived
        } = item;

        return {
          id,
          uuid,
          physicianId: physicianId || "--",
          physicianName:
            item.lastName || item.firstName ? this.formatName(item) : "--",
          taxonomy: taxonomy || "--",
          physicianNpi: npi ? npi.npiNumber || "--" : "--",
          licenseNumber: stateMedicalLicense
            ? stateMedicalLicense.licenseNumber || "--"
            : "--",
          licenseState: licenseState || "--",
          line1: defaultAddress?.line1 || "--",
          line2: defaultAddress?.line2 || "--",
          city: defaultAddress?.city || "--",
          stateProvince: defaultAddress?.stateProvince || "--",
          postalCode: defaultAddress?.postalCode || "--",
          country: defaultAddress?.country || "--",
          licenseStatus:
            licenseStatus && licenseStatus.toUpperCase() === "INACTIVE"
              ? "Deactivated"
              : licenseStatus || null,
          effectivityDate: effectivityDate || "--",
          terminationDate: terminationDate || "--",
          status: archived ? "Deactivated" : "Active",
          itemData: item,
        };
      });
    },
  },
  methods: {
    isPhysicianAssigned(id){
      return this.assignedPhysician.includes(id);
    },
    
    async validateForm() {
      const validateForms = await this.$refs.form.validateAsync();
      return validateForms;
    },

    expandRow(data, isExpanded) {
      if (isExpanded) this.expandedRow = [];
      else this.expandedRow = [data];
    },

    stopPropagation(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    async getLicenseStatuses() {
      try {
        if (!this.licenseStatuses.length) {
          this.licenseStatuses = [
            { text: "Fetching license statues...", disabled: true },
          ];

          const result = await this.actions.getEnumValues("LicenseStatus");

          if (result) {
            this.licenseStatuses = result.enumValues.map((item) => {
              return {
                text: item.name,
                value: item.name,
              };
            });
          } else {
            this.showNotifyMessage({
              type: "danger",
              message: "Unable to fetch data.",
            });
          }
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Unable to fetch data.",
        });
      }
    },
    async doValidateNpiNumber(npi, uuid, physicianNpi) {
      try {
        if (!/^\d{10}$/.test(npi)) {
          return "NPI number must have a min/max field length of 10.";
        }

        const validateNpiExternally = await this.validateNpiNumber(npi);
        if (typeof validateNpiExternally === "string") {
          return validateNpiExternally;
        } else {
          const result = await this.actions.searchNpi(npi);
          if (result.length) {
            return (
              result[0].id === physicianNpi.id || "NPI number already exists."
            );
          } else {
            const isAlreadyUsedLocally =
              this.__Physicians.filter(
                (ph) => ph.npi.npiNumber === npi && ph.uuid !== uuid
              ).length > 0 || this.payorAccountNpi === npi;

            return !isAlreadyUsedLocally || "NPI number already exists.";
          }
        }
      } catch (err) {
        return "Invalid NPI number.";
      }
    },
    async doValidateLicenseNumber(licenseNumber, physicianId) {
      try {
        if (!licenseNumber.length) return "This field is required.";

        const result = await this.actions.getPhysicians({
          filter: {
            stateMedicalLicense: licenseNumber,
            enum_patternMatch: "EXACT",
          },
          limitData: ["physicianId"],
        });

        if (result.length && result[0].id !== physicianId)
          return "License number already exists.";

        return true;
      } catch (err) {
        throw Error(err);
      }
    },
    displayPhysicianModal(index) {
      this.physicianModalIndex = index;
      this.physicianModalVisibility = true;
    },
    cancelSelection() {
      this.physicianModalVisibility = false;
    },
    async getEmailTypes() {
      try {
        if (!this.emailTypes.length) {
          this.emailTypes = [
            { label: "Fetching email types...", disabled: true },
          ];

          const result = await this.actions.getEmailTypes();

          if (result) {
            this.emailTypes = result.map((item) => {
              return {
                id: item.id,
                label: item.label,
              };
            });
          } else {
            this.showNotifyMessage({
              type: "danger",
              message: "Unable to fetch data.",
            });
          }
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Unable to fetch data.",
        });
      }
    },
    async selectedPhysician(e) {
      const { physician } = e;
      let index = this.__Physicians.length;

      this.addPhysician();

      this.expandRow(this.__Physicians[this.__Physicians.length - 1], false);
      const {
        id,
        physicianId,
        firstName,
        middleName,
        lastName,
        taxonomy,
        npi,
        stateMedicalLicense,
        licenseState,
        licenseStatus,
        defaultPhone,
        defaultEmail,
        phones,
        emails,
        effectivityDate,
        terminationDate,
        archived
      } = physician;

      this.__Physicians[index].id = id;
      this.__Physicians[index].physicianId = physicianId || "";
      this.__Physicians[index].firstName = firstName;
      this.__Physicians[index].middleName = middleName || "";
      this.__Physicians[index].lastName = lastName;
      this.__Physicians[index].taxonomy = taxonomy || "";
      this.__Physicians[index].archived = archived;
      this.__Physicians[index].npi = npi || {
        id: null,
        npiNumber: "",
      };

      this.__Physicians[index].stateMedicalLicense = stateMedicalLicense || {
        id: null,
        licenseNumber: "",
      };
      this.__Physicians[index].licenseState = licenseState || "";
      this.__Physicians[index].licenseStatus = licenseStatus || "";
      this.__Physicians[index].defaultPhone = defaultPhone || {
        id: null,
        phone: "",
      };
      this.__Physicians[index].defaultEmail = defaultEmail || {
        id: null,
        email: "",
      };

      this.__Physicians[index].phones = phones
        ? phones.map((phoneItem) => {
            var isDefault = false;
            const { id, phone, areaCode } = phoneItem;

            if (defaultPhone.id === id) isDefault = true;

            return {
              id,
              uuid: uuid.v1(),
              isDefault,
              phone,
              areaCode: areaCode || "",
            };
          })
        : [
            {
              id: null,
              uuid: uuid.v1(),
              isDefault: true,
              phone: "",
              areaCode: "",
            },
          ];

      this.__Physicians[index].emails = emails
        ? emails.map((emailItem) => {
            var isDefault = false;
            const { id, email, emailType } = emailItem;

            if (defaultEmail.id === id) isDefault = true;

            return {
              id,
              uuid: uuid.v1(),
              isDefault,
              email,
              emailTypeId: emailType ? emailType.id : null,
            };
          })
        : [
            {
              id: null,
              uuid: uuid.v1(),
              isDefault: true,
              email: "",
              emailTypeId: null,
            },
          ];

      this.__Physicians[index].effectivityDate = effectivityDate
        ? this.$options.filters.getTimeDate(effectivityDate, "MM/DD/YYYY").zone
        : "";
      this.__Physicians[index].terminationDate = terminationDate
        ? this.$options.filters.getTimeDate(effectivityDate, "MM/DD/YYYY").zone
        : "";

      if (!this.licenseStatuses.length) await this.getLicenseStatuses();

      this.physicianModalVisibility = false;

    },
    clearPhysician(e, physicianUUID) {
      e?.preventDefault();
      e?.stopPropagation();

      const findIndex = this.__Physicians.findIndex(
        (physician) => physician.uuid === physicianUUID
      );
      if (findIndex > -1) this.__Physicians.splice(findIndex, 1);
    },
    parseDate(date) {
      if (!this.isValidDate(date)) return "";

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    closeDateModal(index, dataModel, menu, date) {
      this.__Physicians[index][dataModel] = this.formatDate(date);
      this[menu][index] = false;
    },
    isExistingPhysician(physician) {
      return physician.id !== null;
    },
    addPhysician() {
      this.__Physicians.push({
        id: null,
        uuid: uuid.v1(),
        physicianId: "",
        taxonomy: "",
        firstName: "",
        middleName: "",
        lastName: "",
        npi: {
          id: null,
          npiNumber: "",
        },
        stateMedicalLicense: {
          id: null,
          licenseNumber: "",
        },
        licenseState: "",
        licenseStatus: "",
        defaultPhone: {
          id: null,
          phone: "",
        },
        defaultEmail: {
          id: null,
          email: "",
        },
        phones: [
          {
            id: null,
            uuid: uuid.v1(),
            isDefault: true,
            phone: "",
            areaCode: "",
          },
        ],
        emails: [
          {
            id: null,
            uuid: uuid.v1(),
            isDefault: true,
            email: "",
            emailTypeId: null,
          },
        ],
        effectivityDate: "",
        terminationDate: "",
      });

      this.$nextTick(() => {
        this.panels.push(this.__Physicians.length - 1);
      });

      this.expandRow(this.__Physicians[this.__Physicians.length - 1], false);
    },
    deletePhysician(uuid) {
      const findIndex = this.__Physicians.findIndex(
        (item) => item.uuid === uuid
      );

      if (uuid === this.defaultPhysician) {
        if (findIndex > -1) this.__Physicians.splice(findIndex, 1);

        this.defaultPhysician = this.__Physicians[0].uuid;
      } else {
        if (findIndex > -1) this.__Physicians.splice(findIndex, 1);

        const defaultIndex = this.__Physicians.findIndex(
          (item) => item.uuid === this.defaultPhysician
        );

        if (defaultIndex > -1) {
          this.defaultPhysician = this.__Physicians[defaultIndex].uuid;
        }
      }

      this.$refs.form.resetValidation();
      for (let phone of this.$refs.defaultPhone) {
        phone.$refs.form.resetValidation();
      }
    },
    setModalVisibility(visible = false) {
      this.addEditPhysicianModalVisibility = visible;
      if (!visible) this.selectedPhysicianObj = null;
    },
    editPhysician(physician) {
      this.selectedPhysicianObj = physician;
      this.setModalVisibility(true);
    },
    doCancel(physician) {
      const { uuid } = physician;

      let hasValue = Object.keys(physician).some((key) => {
        if (key !== "uuid" && key !== "id") {
          return !(physician[key] instanceof Object)
            ? physician[key]
            : physician[key]?.id;
        }
      });

      if (hasValue) {
        this.setPromptModalVisibility({
          message: "Are you sure you want to cancel this physician?",
          action: "doRemove",
          id: uuid,
        });
      } else {
        this.clearPhysician(null, uuid);
      }
    },
    async doSave(physician) {
      if (await this.validateForm()) this.$emit("doSave", physician);
      else
        this.showNotifyMessage({
          message: "Please fill all the required fields.",
          type: "danger",
        });
    },
    doRemove(id) {
      this.promptVisibility = false;
      this.clearPhysician(null, id);
    },
    async savePhysician() {
      this.setModalVisibility(false);
      this.showNotifyMessage({
        type: "success",
        message: "Client Physician saved.",
      });
      await this.getPhysicians();
    },
    async removePhysician(physicianId) {
      try {
        await this.actions.updatePayorAccount({
          variables: {
            payorAccountId: {
              type: "UUID!",
              value: this.clientId,
              unincludeToFields: true,
            },
          },
          queries: {
            deletePhysician: {
              physicianId: {
                type: "UUID!",
                value: physicianId,
              },
            },
          },
        });

        this.showNotifyMessage({
          type: "success",
          message: "Client Physician removed.",
        });
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Problem has occurred while saving data.",
        });
      } finally {
        this.promptVisibility = false;
        await this.getPhysicians();
      }
    },
    setPromptModalVisibility({ action, id, message }) {
      this.promptVisibility = true;
      this.promptMessage = message;
      this.promptData = {
        action,
        id,
      };
    },
    async setPromptAction(data) {
      const { action, id } = data;
      await this[action](id);
    },
    async getPhysicians() {
      try {
        this.isLoading = true;

        const result = await this.actions.getPayorAccount({
          variables: {
            property: {
              id: {
                type: "UUID!",
                value: this.clientId,
              },
            },
          },
          limitData: [
            this.buildSubQuery("physicians", [
              "physicianId",
              "taxonomy",
              "firstName",
              "middleName",
              "lastName",
              this.buildSubQuery("npi", ["npiNumber"]),
              this.buildSubQuery("stateMedicalLicense", ["licenseNumber"]),
              "licenseState",
              "licenseStatus",
              this.buildSubQuery("defaultPhone", ["phone", "areaCode"]),
              this.buildSubQuery("phones", ["phone", "areaCode"]),
              this.buildSubQuery("defaultEmail", ["email"]),
              this.buildSubQuery("emails", [
                "email",
                this.buildSubQuery("emailType", []),
              ]),
              this.buildSubQuery("defaultAddress", [
                "line1",
                "line2",
                "city",
                "stateProvince",
                "postalCode",
              ]), //added for iKonek
              "effectivityDate",
              "terminationDate",
              "archived"
            ]),
          ],
        });

        if (result) this.populatePhysicians(result);
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Unable to fetch data.",
        });
      } finally {
        this.isLoading = false;
      }
    },
    populatePhysicians(data) {
      if (data.physicians && data.physicians.length) {
        this.__Physicians = data.physicians.map((item) => {
          const {
            id,
            physicianId,
            taxonomy,
            firstName,
            middleName,
            lastName,
            npi,
            stateMedicalLicense,
            licenseState,
            licenseStatus,
            defaultPhone,
            defaultEmail,
            defaultAddress,
            phones,
            emails,
            effectivityDate,
            terminationDate,
            archived
          } = item;

          return {
            id,
            uuid: id,
            physicianId,
            taxonomy,
            firstName,
            middleName,
            lastName,
            npi,
            stateMedicalLicense,
            licenseState,
            licenseStatus,
            defaultPhone,
            defaultEmail,
            defaultAddress,
            phones:
              phones && phones.length
                ? phones.map((phoneItem) => {
                    var isDefault = false;
                    const { id, phone, areaCode } = phoneItem;

                    if (item.defaultPhone && item.defaultPhone.id === id)
                      isDefault = true;

                    return {
                      id,
                      uuid: uuid.v1(),
                      isDefault,
                      phone,
                      areaCode: areaCode || "",
                    };
                  })
                : [
                    {
                      id: null,
                      uuid: uuid.v1(),
                      isDefault: true,
                      phone: "",
                      phoneTypeId: null,
                      areaCode: "",
                    },
                  ],
            emails:
              emails && emails.length
                ? emails.map((emailItem) => {
                    var isDefault = false;
                    const { id, email, emailType } = emailItem;

                    if (item.defaultEmail && item.defaultEmail.id === id)
                      isDefault = true;

                    return {
                      id,
                      uuid: uuid.v1(),
                      isDefault,
                      email,
                      emailTypeId: emailType ? emailType.id : null,
                    };
                  })
                : [
                    {
                      id: null,
                      uuid: uuid.v1(),
                      isDefault: true,
                      email: "",
                      emailTypeId: null,
                    },
                  ],
            effectivityDate,
            terminationDate,
            archived
          };
        });
      } else {
        this.__Physicians = [];
      }
    },
    formatName(physician) {
      return `${physician.lastName}, ${physician.firstName} ${
        physician.middleName ? physician.middleName : ""
      }`;
    },
  },
  created(){
    this.assignedPhysician = [...this.__Physicians].map((item)=> item.id);
  }
};
