var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"items":_vm.getAddresses,"loading":_vm.isLoading,"headers":_vm.columns,"no-data-text":"Addresses not found.","footer-props":_vm.footerProps,"hide-default-footer":!_vm.editMode ? true : false,"disable-pagination":!_vm.editMode ? true : false},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{attrs:{"height":"80px"}},[_c('td',[_c('v-status',{attrs:{"status":item.default === 'Yes' ? 'default' : 'notDefault'}})],1),_c('td',[_vm._v(_vm._s(item.addressType))]),_c('td',[_vm._v(_vm._s(item.country))]),_c('td',[_vm._v(_vm._s(item.fullAddress))]),_c('td',[_vm._v(_vm._s(item.mainPhone))]),_c('td',[_vm._v(_vm._s(item.mainFax))]),(_vm.editMode)?_c('td',{staticClass:"text-center"},[_c('v-status',{attrs:{"status":item.status}})],1):_vm._e(),_c('td',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","text":""}},'v-btn',attrs,false),on),[_vm._v(" More ")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',[_c('v-list-item',{on:{"click":function($event){return _vm.editClientAddress(item.itemData)}}},[_c('v-list-item-content',[_vm._v("Edit")])],1),(!_vm.editMode)?_c('v-list-item',{on:{"click":function($event){return _vm.deleteClientAddress(item.itemData.uuid)}}},[_vm._v("Remove")]):_vm._e(),(item.status.toUpperCase() === 'DEACTIVATED')?_c('v-list-item',{on:{"click":function($event){return _vm.deArchiveAddress(item.id)}}},[_vm._v("Activate")]):_c('v-list-item',{on:{"click":function($event){return _vm.setPromptModalVisibility({
                      action: 'archiveAddress',
                      id: item.id,
                      message: 'Deactivate Client Address?',
                    })}}},[_vm._v("Deactive")])],1)],1)],1)],1)])]}}])}),_c('div',[_c('v-btn',{attrs:{"id":"add_client_adress","color":"primary"},on:{"click":function($event){return _vm.setModalVisibility(true)}}},[_vm._v("Add Client Address")])],1),(_vm.clientAddressModalVisibility)?_c('v-dialog',{attrs:{"persistent":"","width":"45rem"},model:{value:(_vm.clientAddressModalVisibility),callback:function ($$v) {_vm.clientAddressModalVisibility=$$v},expression:"clientAddressModalVisibility"}},[_c('ClientAddressModal',{attrs:{"clientId":_vm.clientId ? _vm.clientId : null,"existingAddress":_vm.selectedAddress,"editMode":_vm.editMode},on:{"doSave":_vm.saveClientAddress,"addAddress":_vm.addClientAddress,"doCancel":function($event){return _vm.setModalVisibility(false)}}})],1):_vm._e(),_c('v-prompt-modal',{ref:"promptModal",attrs:{"promptVisibility":_vm.promptVisibility,"message":_vm.promptMessage,"emitData":_vm.promptData},on:{"update:promptVisibility":function($event){_vm.promptVisibility=$event},"update:prompt-visibility":function($event){_vm.promptVisibility=$event},"doOk":_vm.setPromptAction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }