var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"contractualTable",attrs:{"items":_vm.getContractInfos,"loading":_vm.isLoading,"headers":_vm.columns,"no-data-text":"Contractual information not found.","footer-props":_vm.footerProps,"hide-default-footer":true,"disable-pagination":!_vm.editMode ? true : false},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{attrs:{"height":"80px"}},[_c('td',[_vm._v(_vm._s(item.statementType))]),_c('td',[_vm._v(_vm._s(_vm.billingTermDisplay(item)))]),_c('td',[_vm._v(" "+_vm._s(item.paymentTerm === "Other" ? ((item.paymentTerm) + " (" + (item.paymentTermOther) + ")") : item.paymentTerm)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.paymentType === "Other" ? ((item.paymentType) + " (" + (item.paymentTypeOther) + ")") : item.paymentType)+" ")]),_c('td',[_c('div',{staticClass:"d-flex flex-column"},[(item.methodType)?_c('span',{staticClass:"capsule-text"},[_vm._v(_vm._s(item.methodType))]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.selectedPreferredMethod.length >= 20)?_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.selectedPreferredMethod.substring(0, 20))+"... ")]):_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.selectedPreferredMethod)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.selectedPreferredMethod))])])],1)]),_c('td',[_vm._v(_vm._s(item.feeSchedule))]),(_vm.editMode)?_c('td',{staticClass:"text-center"},[_c('v-status',{attrs:{"status":item.status}})],1):_vm._e(),_c('td',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","text":""}},'v-btn',attrs,false),on),[_vm._v(" More ")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',[_c('v-list-item',[_c('v-list-item-content',{on:{"click":function($event){return _vm.editContractInfo(item.itemData)}}},[_vm._v("Edit")])],1),(!_vm.editMode)?_c('v-list-item',{on:{"click":function($event){return _vm.deleteContractualInformation(item.itemData.uuid)}}},[_vm._v("Remove")]):_vm._e()],1)],1)],1)],1)])]}}])}),_c('div',[(_vm.getContractInfos.length ==0)?_c('v-btn',{attrs:{"id":"add_client_billing","color":"primary"},on:{"click":function($event){return _vm.setModalVisibility(true)}}},[_vm._v("Add Client Billing")]):_vm._e()],1),(_vm.contractModalVisibility)?_c('v-dialog',{attrs:{"persistent":"","width":"45rem"},model:{value:(_vm.contractModalVisibility),callback:function ($$v) {_vm.contractModalVisibility=$$v},expression:"contractModalVisibility"}},[_c('ClientContractModal',{attrs:{"clientId":_vm.clientId,"existingContract":_vm.selectedContract,"contractualInformations":_vm.__ContractualInformations,"addressInformations":_vm.addressInfo,"contactInformations":_vm.contactInfo,"editMode":_vm.editMode},on:{"doSave":_vm.saveContractInfo,"addContract":_vm.addContractualInformation,"doCancel":function($event){return _vm.setModalVisibility(false)}}})],1):_vm._e(),_c('v-prompt-modal',{ref:"promptModal",attrs:{"promptVisibility":_vm.promptVisibility,"message":_vm.promptMessage,"emitData":_vm.promptData},on:{"update:promptVisibility":function($event){_vm.promptVisibility=$event},"update:prompt-visibility":function($event){_vm.promptVisibility=$event},"doOk":_vm.setPromptAction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }