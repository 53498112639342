import Actions from "modules/actions/client-accounts-actions";

export default {
  name: "PhysicianModal",
  props: {
    physicianModalIndex: {
      type: Number,
      required: true,
    },
    linkedPhysicians: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      actions: new Actions(),
      physicians: [],
      isLoading: false,
      offset: null,
      physicianObj: null,
      searchVal: "",
    };
  },
  watch: {
    async isLoading(val) {
      if (val) {
        await this.getPhysicians();
      }
    },
  },
  computed: {
    hasSelectedPhysician() {
      return this.physicianObj && this.physicianObj.id !== null;
    },
  },
  methods: {
    async getPhysicians() {
      try {
        const result = await this.actions.getPhysicians({
          limit: 20,
          offset: this.offset,
          filter: this.searchVal
            ? {
                firstName: this.searchVal,
                lastName: this.searchVal,
                npiNumber: this.searchVal,
                logicalOperator: "OR",
              }
            : null,
        });

        if (result && result.length) {
          if (this.physicians.length) {
            this.physicians = this.physicians.concat(
              this.sanitizeResult(result)
            );
          } else {
            this.physicians = this.sanitizeResult(result);
          }
        }
      } catch (err) {
        this.showNotifyMessage({
          message: "Unable to fetch data.",
          type: "danger",
        });
      } finally {
        this.isLoading = false;
        this.offset = this.physicians.length || 0;
      }
    },
    async searchPhysician() {
      this.searchDebounce();
    },
    sanitizeResult(data) {
      return data.map((item) => {
        const {
          id,
          physicianId,
          firstName,
          middleName,
          lastName,
          taxonomy,
          npi,
          stateMedicalLicense,
          licenseState,
          licenseStatus,
          defaultPhone,
          defaultEmail,
          phones,
          emails,
          effectivityDate,
          terminationDate,
          archived
        } = item;

        return {
          id,
          physicianId,
          firstName,
          middleName,
          lastName,
          taxonomy,
          npi,
          stateMedicalLicense,
          licenseState,
          licenseStatus,
          defaultPhone,
          defaultEmail,
          phones,
          emails,
          effectivityDate,
          terminationDate,
          archived
        };
      });
    },
    selectPhysician() {
      this.$emit("selectedPhysician", {
        physician: this.physicianObj,
        index: this.physicianModalIndex,
      });
    },
    addNew(){
      this.$emit("addNew");
    },
    cancelSelection() {
      this.$emit("cancelSelection");
    },
    formatItem(item) {
      const npiNumber = item.npi ? `(${item.npi.npiNumber})` : "";
      return `${item.lastName}, ${item.firstName}, ${npiNumber}`;
    },
    isAlreadyLinked(physicianId) {
      const findLinkedPhysician =
        this.linkedPhysicians &&
        this.linkedPhysicians.filter((item) => item === physicianId).length > 0;

      return findLinkedPhysician;
    },
  },
  async created() {
    this.searchDebounce = await this.debounce(() => {
      this.isLoading = true;
      this.physicians = [];
      this.offset = null;
    }, 600);
  },
};
