import { uuid } from "vue-uuid";

import Test from "store/models/Test";

import ClientContractModal from "modules/main/ClientsPhysicians/Clients/modals/ClientContractModal";

import clientAccountActions from "modules/actions/client-accounts-actions";

import startCase from "lodash/startCase";

export default {
  name: "ClientContractualInfo",
  components: {
    ClientContractModal,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    editMode: {
      required: false,
      type: Boolean,
    },
    clientId: {
      required: false,
    },
    addressInfo: {
      type: Array,
    },
    contactInfo: {
      type: Array,
    },
  },
  data() {
    return {
      testModel: Test,
      actions: new clientAccountActions(),
      billingStatementTypes: [],
      statementBillingTerms: [],
      defaultContract: "",
      contractModalVisibility: false,
      isLoading: false,
      selectedContract: null,
      promptData: null,
      promptVisibility: false,
      promptMessage: "",
      rules: {
        required: (val) => {
          if (typeof val === "object" && val !== null)
            return (
              (val && val.id && val.id !== null) || "This field is required"
            );

          return (
            (val !== null && val.length !== 0) || "This field is required."
          );
        },
        // validateRate: (val) => {
        //   return (
        //     Math.sign(val) <= -1 ||
        //     Math.sign(val) >= 1 ||
        //     "Contract rate must not be -0.00"
        //   );
        // },
        // validateOrderCode: async (val, bcci) =>
        //   await this.doValidateOrderCode(val, bcci),
      },
      columns: [
        {
          text: "Statement Type",
          value: "statementType",
          sortable: false,
        },
        {
          text: "Statement Billing Term",
          value: "statementBillingTerm",
          sortable: false,
        },
        {
          text: "Payment Term",
          value: "paymentTerm",
          sortable: false,
        },
        {
          text: "Payment Type",
          value: "paymentType",
          sortable: false,
        },
        {
          text: "Preferred Method",
          value: "preferredMethod",
          sortable: false,
        },
        {
          text: "Fee Schedule",
          value: "feeSchedule",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ],
      footerProps: {
        showFirstLastPage: true,
        "items-per-page-options": [5, 10, 15, 20],
      },
    };
  },
  computed: {
    __ContractualInformations: {
      get() {
        return this.data;
      },
      set(value) {
        this.$emit("update:data", value);
      },
    },
    getContractInfos() {
      return  this.__ContractualInformations.map((item) => {
        const {
          id,
          archived,
          billingStatementType,
          paymentTerm,
          paymentTermOther,
          paymentType,
          paymentTypeOther,
          methodType,
          selectedPreferredMethod,
          feeSchedule,
          statementBillingTerm,
          statementBillingTermOther,
          // test,
        } = item;

        return {
          id,
          statementType: billingStatementType || "--",
          statementBillingTerm: statementBillingTerm || "--",
          statementBillingTermOther: statementBillingTermOther || "",
          paymentTerm: paymentTerm?.label || "--",
          paymentTermOther: paymentTermOther || "--",
          paymentType: paymentType?.label || "--",
          paymentTypeOther: paymentTypeOther || "--",
          methodType: methodType?.text,
          selectedPreferredMethod: selectedPreferredMethod?.label || "--",
          feeSchedule: feeSchedule || "--",
          archived: archived || false,
          status: archived ? "Deactivated" : "Active",
          itemData: item,
        };
      });
    },
  },
  methods: {
    billingTermDisplay(contract) {
      if (contract.statementBillingTerm.toLowerCase() === "other") {
        return (
          contract.statementBillingTerm +
          ` (${contract.statementBillingTermOther})`
        );
      }

      return contract.statementBillingTerm.toUpperCase() === "BIMONTHLY"
        ? "Bi-Monthly"
        : contract.statementBillingTerm;
    },
    async getEnum(enumName) {
      try {
        if (!this[`${this.formatToCamelCase(enumName)}s`].length) {
          this[`${this.formatToCamelCase(enumName)}s`] = [
            {
              text: `Fetching ${this.formaText(enumName)}s...`,
              disabled: true,
            },
          ];

          const result = await this.actions.getEnumValues(enumName);

          if (result) {
            if (enumName.toUpperCase() === "STATEMENTBILLINGTERM") {
              this[`${this.formatToCamelCase(enumName)}s`] =
                result.enumValues.map((item) => {
                  return {
                    text:
                      item.name.toUpperCase() === "BIMONTHLY"
                        ? "Bi-Monthly"
                        : item.name,
                    value: item.name,
                  };
                });
            } else {
              this[`${this.formatToCamelCase(enumName)}s`] =
                result.enumValues.map((item) => {
                  return {
                    text: item.name,
                    value: item.name,
                  };
                });
            }
          } else {
            this.showNotifyMessage({
              type: "danger",
              message: `Unable to fetch data.`,
            });
          }
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: `Unable to fetch data.`,
        });
      }
    },
    populateTestId(test, index) {
      const { id, label } = test;
      this.__ContractualInformations[index].test = {
        id,
        label,
      };
    },
    formaText(value) {
      const result = value.replace(/([A-Z])/g, " $1");
      return result.toLowerCase();
    },
    formatToCamelCase(str) {
      return str.charAt(0).toLowerCase() + str.slice(1);
    },
    addContractualInformation(contract) {
      if (!this.selectedContract) {
        this.__ContractualInformations.push({
          ...contract,
          id: null,
          uuid: uuid.v1(),
        });
      } else {
        this.__ContractualInformations = this.__ContractualInformations.map(
          (info) => {
            if (info.uuid == contract.uuid) {
              return {
                ...contract,
              };
            }
            return info;
          }
        );
      }
      this.setModalVisibility(false);
    },
    deleteContractualInformation(uuid) {
      const findIndex = this.__ContractualInformations.findIndex(
        (item) => item.uuid === uuid
      );

      if (uuid === this.defaultContract) {
        if (findIndex > -1) this.__ContractualInformations.splice(findIndex, 1);

        this.defaultContract = this.__ContractualInformations[0].uuid;
      } else {
        if (findIndex > -1) this.__ContractualInformations.splice(findIndex, 1);

        const defaultIndex = this.__ContractualInformations.findIndex(
          (item) => item.uuid === this.defaultContract
        );

        if (defaultIndex > -1) {
          this.defaultContract =
            this.__ContractualInformations[defaultIndex].uuid;
        }
      }
    },
    // async doValidateOrderCode(val, bcci) {
    //   try {
    //     const result = await this.actions.searchBCCI({
    //       filter: {
    //         orderCode: val,
    //         enum_patternMatch: "EXACT",
    //       },
    //       limitData: ["orderCode"],
    //     });

    //     if (!result.length) {
    //       const usedLocally =
    //         this.__ContractualInformations.filter(
    //           (item) => item.orderCode === val && item.uuid !== bcci.uuid
    //         ).length > 0;

    //       if (usedLocally) return "Order Code already exists.";
    //     } else {
    //       if (result.length > 1 || result[0].id !== bcci.id) {
    //         return "Order Code already exists.";
    //       }
    //     }

    //     return true;
    //   } catch (err) {
    //     this.showNotifyMessage({
    //       type: "danger",
    //       message: `A problem has occured while validating the order code.`,
    //     });
    //   }
    // },
    setModalVisibility(visible = false) {
      this.contractModalVisibility = visible;
      if (!visible) this.selectedContract = null;
    },
    editContractInfo(contract) {
      this.selectedContract = contract;
      this.setModalVisibility(true);
    },
    async saveContractInfo() {
      this.setModalVisibility(false);
      this.showNotifyMessage({
        type: "success",
        message: "Client Contract saved.",
      });
      await this.getContractualInformations();
    },
    async archiveContractualInformation(contractId) {
      try {
        await this.actions.archiveBCCI({
          billingClientContractualInformationId: {
            type: "UUID!",
            value: contractId,
            unincludeToFields: true,
          },
        });

        this.showNotifyMessage({
          type: "success",
          message: "Client Contract set as deactivated.",
        });
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Problem has occurred while saving data.",
        });
      } finally {
        this.promptVisibility = false;
        this.getContractualInformations();
      }
    },
    async deArchiveContractualInformation(contractId) {
      try {
        await this.actions.deArchiveBCCI({
          billingClientContractualInformationId: {
            type: "UUID!",
            value: contractId,
            unincludeToFields: true,
          },
        });

        this.showNotifyMessage({
          type: "success",
          message: "Client Contract activated.",
        });
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Problem has occurred while saving data.",
        });
      } finally {
        this.promptVisibility = false;
        this.getContractualInformations();
      }
    },
    setPromptModalVisibility({ action, id, message }) {
      this.promptVisibility = true;
      this.promptMessage = message;
      this.promptData = {
        action,
        id,
      };
    },
    async setPromptAction(data) {
      const { action, id } = data;
      await this[action](id);
    },

    setUpListeners() {
      this.$eventBus.$on("refreshClientContractualInfo", async () => {
        await this.getContractualInformations();
      });
    },
    async getContractualInformations() {
      try {
        this.isLoading = true;

        const result = await this.actions.getPayorAccount({
          variables: {
            property: {
              id: {
                type: "UUID!",
                value: this.clientId,
              },
            },
          },
          limitData: [
            this.buildSubQuery("contractualInformations(archive: true)", [
              "billingStatementType",
              "statementBillingTerm",
              "feeSchedule",
              "archived",
              this.buildSubQuery(
                "preferredMethodType",
                ["methodType", "selectedId", "displayLabel"],
                false
              ),
            ]),
          ],
        });

        if (result) this.populateContractualInformations(result);
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Unable to fetch data.",
        });
      } finally {
        this.isLoading = false;
      }
    },
    populateContractualInformations(data) {
      if (data.contractualInformations && data.contractualInformations.length)
        this.__ContractualInformations = data.contractualInformations.map(
          (item) => {
            const {
              id,
              billingStatementType,
              statementBillingTerm,
              feeSchedule,
              archived,
              preferredMethodType,
            } = item;

            return {
              id,
              billingStatementType: billingStatementType || "--",
              statementBillingTerm: statementBillingTerm || "--",
              feeSchedule: feeSchedule || "--",
              methodType: {
                text: startCase(
                  preferredMethodType?.methodType?.replace("_", " ")
                ),
                value: preferredMethodType?.methodType,
              },
              selectedPreferredMethod: {
                id: preferredMethodType?.selectedId,
                label: preferredMethodType?.displayLabel,
              },
              archived,
            };
          }
        );
    },
  },
  created() {
    if (this.editMode) {
      this.setUpListeners();
      this.columns.splice(this.columns.length - 1, 0, {
        text: "Status",
        align: "center",
        value: "status",
        sortable: false,
      });
    }
  },
};
