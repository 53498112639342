var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-scroll"},[_c('v-data-table',{attrs:{"items":_vm.contactInformations,"loading":_vm.isLoading,"headers":_vm.columns,"no-data-text":"Contact information not found.","footer-props":_vm.footerProps,"hide-default-footer":!_vm.editMode ? true : false,"disable-pagination":!_vm.editMode ? true : false},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{attrs:{"height":"80px"}},[_c('td',[_c('v-status',{attrs:{"status":item.default === 'Yes' ? 'default' : 'notDefault'}})],1),_c('td',[_vm._v(_vm._s(item.contactType))]),_c('td',[_vm._v(_vm._s(item.contactName))]),_c('td',[_vm._v(_vm._s(item.contactTitle))]),_c('td',[_vm._v(_vm._s(item.contactPhone))]),_c('td',[_vm._v(_vm._s(item.contactFax))]),_c('td',[_vm._v(_vm._s(item.contactEmailAddress))]),(_vm.editMode)?_c('td',{staticClass:"text-center"},[_c('v-status',{attrs:{"status":item.status}})],1):_vm._e(),_c('td',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","text":""}},'v-btn',attrs,false),on),[_vm._v(" More ")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',[_c('v-list-item',{on:{"click":function($event){return _vm.editContactInfo(item.itemData)}}},[_c('v-list-item-content',[_vm._v("Edit")])],1),(!_vm.editMode)?_c('v-list-item',{on:{"click":function($event){return _vm.deleteContactInformation(item.itemData.uuid)}}},[_vm._v("Remove")]):_vm._e(),(item.archived)?_c('v-list-item',{on:{"click":function($event){return _vm.deArchiveContactInformation(item.id)}}},[_c('v-list-item-content',[_vm._v("Activate")])],1):_c('v-list-item',{on:{"click":function($event){return _vm.setPromptModalVisibility({
                      action: 'archiveContactInformation',
                      id: item.id,
                      message: 'Deactivate Client Contact?',
                    })}}},[_c('v-list-item-content',[_vm._v(" Deactive ")])],1)],1)],1)],1)],1)])]}}])}),_c('div',[_c('v-btn',{attrs:{"id":"add_client_contact","color":"primary"},on:{"click":function($event){return _vm.setModalVisibility(true)}}},[_vm._v("Add Client Contact")])],1),(_vm.contactModalVisibility)?_c('v-dialog',{attrs:{"persistent":"","width":"45rem"},model:{value:(_vm.contactModalVisibility),callback:function ($$v) {_vm.contactModalVisibility=$$v},expression:"contactModalVisibility"}},[_c('ClientContactModal',{attrs:{"clientId":_vm.clientId,"existingContact":_vm.selectedContact,"editMode":_vm.editMode},on:{"doSave":_vm.saveContactInfo,"addContact":_vm.addContactInformation,"doCancel":function($event){return _vm.setModalVisibility(false)}}})],1):_vm._e(),_c('v-prompt-modal',{ref:"promptModal",attrs:{"promptVisibility":_vm.promptVisibility,"message":_vm.promptMessage,"emitData":_vm.promptData},on:{"update:promptVisibility":function($event){_vm.promptVisibility=$event},"update:prompt-visibility":function($event){_vm.promptVisibility=$event},"doOk":_vm.setPromptAction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }