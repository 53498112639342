var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',{attrs:{"color":"secondary font-weight-bold white--text"}},[_vm._v(" "+_vm._s(_vm.headerLabel)+" ")]),_c('v-card-text',{staticClass:"pr-6 pl-6"},[_c('h3',{staticClass:"section-field-note"},[_vm._v(" All fields are required except marked optional. ")]),_c('v-row',[_c('v-col',[_c('v-form',{directives:[{name:"async-form",rawName:"v-async-form"}],ref:"form",attrs:{"lazy-validation":""}},[_c('v-switch',{attrs:{"label":"Default","disabled":_vm.isDefaultInitially || _vm.disableField},model:{value:(_vm.address.isDefault),callback:function ($$v) {_vm.$set(_vm.address, "isDefault", $$v)},expression:"address.isDefault"}}),_c('v-address-section',{ref:"clientAddress",attrs:{"address":_vm.address,"customFields":[
                {
                  type: 'type',
                  label: 'Address Type',
                  rules: [].concat( _vm.rules.requiredAddressType ),
                  returnObject: true,
                  columns: 6,
                },
                {
                  type: 'country',
                  label: 'Country',
                  rules: [_vm.rules.required],
                  disabled: _vm.disableField,
                  columns: 6,
                },
                {
                  type: 'line1',
                  label: 'Address Line 1',
                  rules: [_vm.rules.required],
                  disabled: _vm.disableField,
                },
                {
                  type: 'line2',
                  label: 'Address Line 2 (Optional)',
                  disabled: _vm.disableField,
                },

                {
                  type: 'city',
                  label: 'City',
                  rules: [_vm.rules.required],
                  disabled: _vm.disableField,
                  columns: 6,
                },
                {
                  type: 'state',
                  label: 'State',
                  rules: [_vm.rules.required],
                  disabled: _vm.disableField,
                  columns: 6,
                },
                {
                  type: 'zipCode',
                  label: 'Postal Code',
                  rules: [_vm.rules.required],
                  disabled: _vm.disableField,
                  columns: 6,
                } ]},on:{"update:address":function($event){_vm.address=$event}}}),_c('v-phone-section-2',{ref:"mainPhone",attrs:{"id":"contact_phone_section","label":"Main Phone No","phones":[_vm.address.mainPhone],"singleSelection":true,"disabled":_vm.disableField,"setPhoneMask":"###-######"}}),_c('v-phone-section-2',{ref:"mainFax",attrs:{"id":"contact_fax_section","label":"Main Fax No","phones":[_vm.address.mainFax],"singleSelection":true,"disabled":_vm.disableField,"setPhoneMask":"###-######"}}),_c('v-row',{staticClass:"text-right"},[_c('v-col',[_c('v-btn',{attrs:{"id":"negative_btn","disabled":_vm.isLoading},on:{"click":_vm.cancelSaving}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"id":"positive_btn","color":"primary","disabled":_vm.isLoading},on:{"click":_vm.saveAddress}},[_vm._v(_vm._s(_vm.btnLabel))])],1)],1)],1)],1)],1)],1)],1),_c('v-prompt-modal',{ref:"promptModal",attrs:{"promptVisibility":_vm.prompt.visibility,"message":_vm.prompt.message,"positiveBtnTxt":"Yes","negativeBtnTxt":"No"},on:{"update:promptVisibility":function($event){return _vm.$set(_vm.prompt, "visibility", $event)},"update:prompt-visibility":function($event){return _vm.$set(_vm.prompt, "visibility", $event)},"doOk":function($event){return _vm.doPromptAction(_vm.prompt.action)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }