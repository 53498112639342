import { uuid } from "vue-uuid";

import Test from "store/models/Test";

import clientAccountActions from "modules/actions/client-accounts-actions";
import FeeScheduleModal from "modules/main/ClientsPhysicians/Clients/modals/FeeSchedule";


export default {
  name: "FeeSchedule",
  components: {
    FeeScheduleModal
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    editMode: {
      required: false,
      type: Boolean,
    },
    clientId: {
      required: false,
    },
  },
  data() {
    return {
      testModel: Test,
      actions: new clientAccountActions(),
      feeModalVisibility: false,
      isLoading: false,
      selectedFeeSchedule: null,
      defaultFeeSchedule: "",
      promptData: null,
      promptVisibility: false,
      promptMessage: "",
      columns: [
        {
          text: "Name",
          value: "feeName",
          sortable: false,
        },
        {
          text: "Test",
          value: "test",
          sortable: false,
        },
        {
          text: "Effective Date",
          value: "effectiveDate",
          sortable: false,
        },
        {
          text: "Expiration Date",
          value: "expirationDate",
          sortable: false,
        },
        {
          text: "Cost Per Test",
          value: "baseRate",
          sortable: false,
        },
        {
          text: "Discount Per Test",
          value: "discountPercentage",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ],
      footerProps: {
        showFirstLastPage: true,
        "items-per-page-options": [5, 10, 15, 20],
      },
    };
  },
  computed: {
    __FeeSchedule: {
      get() {
        return this.data;
      },
      set(value) {
        this.$emit("update:data", value);
      },
    },
    feeSchedules() {

      return this.data.map((item) => {
        const {
          id,
          feeName,
          test,
          effectivityDate,
          expirationDate,
          baseRate,
          discountPercentage
        } = item;
        
        return {
          id,
          feeName: feeName || "--",
          test: test ? test.label || "--" : "--",
          effectivityDate: effectivityDate || "--",
          expirationDate: expirationDate || '--',
          baseRate: baseRate || "--",
          discountPercentage: discountPercentage || "--",
          itemData: item,
        };
          
      });
      
    },
  },
  methods: {
    populateTestId(test, index) {
      const { id, label } = test;
      this.__FeeSchedule[index].test = {
        id,
        label,
      };
    },
    formaText(value) {
      const result = value.replace(/([A-Z])/g, " $1");
      return result.toLowerCase();
    },
    formatToCamelCase(str) {
      return str.charAt(0).toLowerCase() + str.slice(1);
    },
    addFeeSchedule() {
      this.__FeeSchedule.push({
        id: null,
        uuid: uuid.v1(),
        feeName: "",
        test: {
          id: null,
          label: "",
        },
        effectivityDate: "",
        expirationDate: "",
        baseRate: "",
        discountPercentage: "",
      });
    },
    setModalVisibility(visible = false) {
      this.feeModalVisibility = visible;
      if (!visible) this.selectedFeeSchedule = null;
    },
    editFeeSchedule(feeSched) {
      this.selectedFeeSchedule = feeSched;
      this.setModalVisibility(true);
    },
    async saveFeeSchedule() {
      this.setModalVisibility(false);
      this.showNotifyMessage({
        type: "success",
        message: "Fee Schedule saved.",
      });
      await this.getFeeSchedule();
    },
    setPromptModalVisibility({ action, id, message }) {
      this.promptVisibility = true;
      this.promptMessage = message;
      this.promptData = {
        action,
        id,
      };
    },
    async setPromptAction(data) {
      const { action, id } = data;
      await this[action](id);
    },
    async getFeeSchedule() {
      try {
        this.isLoading = true;

        const result = await this.actions.getPayorAccount({
          variables: {
            property: {
              id: {
                type: "UUID!",
                value: this.clientId,
              },
            },
          },
          limitData: [
            this.buildSubQuery("feeSchedules", [
              "feeName",
              this.buildSubQuery("test", ["label"]),
              "effectivityDate",
              "expirationDate",
              "baseRate",
              "discountPercentage"
            ]),
          ],
        });

        if (result) this.populateFeeSchedule(result);
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Unable to fetch data.",
        });
      } finally {
        this.isLoading = false;
      }
    },
    populateFeeSchedule(data) {
      if (data.feeSchedule && data.feeSchedule.length)
        this.__FeeSchedule = data.feeSchedule.map(
          (item) => {
            const {
              id,
              feeName,
              test,
              effectivityDate,
              expirationDate,
              baseRate,
              discountPercentage
            } = item;

            return {
              id,
              feeName,
              test: test
                ? {
                    id: test.id,
                    label: test.label,
                  }
                : {
                    id: null,
                    label: "",
                  },
              effectivityDate,
              expirationDate,
              baseRate,
              discountPercentage
            };
          }
        );
    },
  },
};
