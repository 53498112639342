import actions from "modules/actions/client-accounts-actions";

import startCase from "lodash/startCase";

export default {
  name: "ClientGeneralInfo",
  props: {
    clientId: {
      required: true,
    },
    data: {
      required: true,
      type: Object,
    },
    editMode: {
      required: false,
      type: Boolean,
    },
    isSaving: {
      required: false,
      type: Boolean,
    },
    isEditing: {
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {
      actions: new actions(),
      clientTypes: [],
      exclusions: [],
      menu1: false,
      menu2: false,
      date1: "",
      date2: "",
      rules: {
        required: (val) =>
          (val !== null && val.length !== 0) || "This field is required.",
        validateClientId: async (val) => await this.doValidateClientId(val),
        validateNpi: async (val) => await this.doValidateNpiNumber(val),
        validateTIN: async (val) => await this.doValidateTIN(val),
        validateTaxonomy: (val) => this.doValidateTaxonomy(val),
        validDate: (val) => this.isValidDate(val) || "Invalid date format.",
        validTerminationDate: (val, effectivityDate) => {
          const terminationDate = new Date(val);
          const effectDate = new Date(effectivityDate);

          if (effectivityDate === null || !effectivityDate.length) return true;

          return (
            terminationDate >= effectDate ||
            "Termination date must not be less than the effective date."
          );
        },
      },
      billTypes: [],
    };
  },
  computed: {
    __Info: {
      get() {
        return this.data;
      },
      set(value) {
        this.$emit("update:data", value);
      },
    },
    __IsEditing: {
      get() {
        return this.isEditing;
      },
      set(value) {
        this.$emit("update:isEditing", value);
      },
    },
    fieldDisabled() {
      return (!this.isEditing && this.editMode) || this.isSaving;
    },
    showExclusionOther() {
      return this.__Info.exclusion?.label?.toLowerCase() == "other";
    },
  },
  methods: {
    async getPayorAccountTypes() {
      try {
        if (!this.clientTypes.length) {
          this.clientTypes = [
            { text: "Fetching client types...", disabled: true },
          ];

          const result = await this.actions.getEnumValues("SubType");

          if (result) {
            this.clientTypes = result.enumValues
              .filter((item) => /^CLIENT_/.test(item.name))
              .map((item) => {
                return {
                  text: this.formaText(item.name),
                  value: item.name,
                };
              });
          } else {
            this.showNotifyMessage({
              type: "danger",
              message: "Unable to fetch data.",
            });
          }
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Unable to fetch data.",
        });
      }
    },
    async getExclusions() {
      if (this.exclusions.length) return;

      try {
        this.exclusions = [
          { label: "Fetching exclusion types...", disabled: true },
        ];

        const result = await this.actions.getExclusions();
        if (result) {
          this.exclusions = result.map((item) => {
            return {
              id: item.id,
              label: item.label,
            };
          });
        } else {
          this.showNotifyMessage({
            type: "danger",
            message: "Something went wrong while fetching exclusion types.",
          });
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Something went wrong while fetching exclusion types.",
        });
      }
    },
    async getClientBillTypes() {
      try {
        this.exclusions = [{ label: "Fetching bill types...", disabled: true }];

        const { enumValues } = await this.actions.getEnumValues(
          "VbsClientBillTypesEnum"
        );
        if (enumValues) {
          this.billTypes = enumValues.map((item) => {
            return {
              text: startCase(item.name.toLowerCase().replaceAll("_", " ")),
              value: item.name,
            };
          });
        } else {
          this.showNotifyMessage({
            type: "danger",
            message:
              "Something went wrong while fetching client bill types types.",
          });
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message:
            "Something went wrong while fetching client bill types types.",
        });
      }
    },
    async doValidateClientId(clientId) {
      try {
        if (!clientId.length) return "This field is required.";

        const result = await this.actions.searchPayorAccount({
          filter: {
            payorCode: clientId,
            enum_patternMatch: "EXACT",
            archive: true,
            logicalOperator: "AND",
          },
          limitData: ["payorCode"],
        });

        if (result.length) {
          return result[0].id === this.clientId || "Client ID already exists.";
        }

        return true;
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Error validating client id.",
        });
      }
    },
    async doValidateNpiNumber(npi) {
      try {
        if (npi.length < 10 || npi.length > 10)
          return "NPI number must have a min/max field length of 10.";

        if (!/^\d{10}$/.test(npi)) return "Invalid NPI number.";

        const validateNpiExternally = await this.validateNpiNumber(npi);
        if (typeof validateNpiExternally === "string") {
          return validateNpiExternally;
        } else {
          const result = await this.actions.searchNpi(npi);
          if (result.length) {
            return (
              result[0].id === this.__Info.npi.id ||
              "NPI number already exists."
            );
          }
        }

        return true;
      } catch (err) {
        return "Invalid NPI number.";
      }
    },
    async doValidateTIN(tinNumber) {
      try {
        if (!this.validLength(tinNumber, 9, 9))
          return "TIN must not be less than or greater than 9 Characters.";

        const result = await this.actions.searchPayorAccount({
          filter: {
            tinNumber,
            enum_patternMatch: "EXACT",
          },
          limitData: ["tinNumber"],
        });

        if (result.length) {
          return result[0].id === this.clientId || "TIN number already exists.";
        }

        return true;
      } catch (err) {
        return "Error validating TIN number.";
      }
    },
    doValidateTaxonomy(taxonomy) {
      try {
        if (!taxonomy || !taxonomy.length) return true;

        if (taxonomy.length < 10)
          return "Taxonomy Code must not be less than 10 characters.";

        if (taxonomy.length > 15)
          return "Taxonomy Code must not be greater than 15 characters.";

        return true;
      } catch (err) {
        return "Error validating Taxonomy Code.";
      }
    },
    validLength(val, min = 0, max = 10) {
      if (val.length < min || val.length > max) return false;

      return true;
    },
    formaText(value) {
      let result = value.replace(/^CLIENT_/, "");
      if (result.toUpperCase() === "WHOLESALE_B2B") {
        result = "Wholesale / Business to Business";
      } else {
        result = result.replace(/([A-Z])/g, " $1");
      }

      return result;
    },
    parseDate(date) {
      if (!this.isValidDate(date)) return "";

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    closeDateModal(dataModel, menu, date) {
      this.__Info[dataModel] = this.formatDate(date);
      this[menu] = false;
    },
    async doSave() {
      const validate = await this.$refs.form.validateAsync();
      if (validate) this.$emit("doSave");
    },
    doEdit() {
      this.__IsEditing = true;
    },
    doCancel() {
      this.$emit("doCancel");
    },

    async validateForm() {
      let validateForms = await this.$refs.form.validateAsync();
      return validateForms;
    },
  },

  async created() {
    if (!this.editMode) return;

    try {
      await this.getPayorAccountTypes();
      await this.getExclusions();
      await this.getClientBillTypes();
    } catch (err) {
      this.showNotifyMessage({
        message: "Unable to fetch data.",
        type: "danger",
      });
    }
  },
};
