import { uuid } from "vue-uuid";

import Actions from "modules/actions/client-accounts-actions";

import ClientContactModal from "modules/main/ClientsPhysicians/Clients/modals/ClientContactModal";

export default {
  name: "ClientContactInfo",
  components: {
    ClientContactModal,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    editMode: {
      required: false,
      type: Boolean,
    },
    clientId: {
      required: false,
    },
  },
  data() {
    return {
      actions: new Actions(),
      emailTypes: [],
      defaultContact: "",
      contactModalVisibility: false,
      isLoading: false,
      selectedContact: null,
      promptData: null,
      promptVisibility: false,
      promptMessage: "",
      rules: {
        required: (val) => {
          if (typeof val === "object")
            return (
              (val !== null && val.id && val.id !== null) ||
              "This field is required"
            );

          return (
            (val !== null && val.length !== 0) || "This field is required."
          );
        },
        validateEmailType: (val, email) => {
          return email.length > 0
            ? (val !== null && val.length > 0) || "This field is required."
            : true;
        },
        validateEmail: (val) =>
          this.validateEmail(val) || "Invalid email format.",
      },
      columns: [
        {
          text: "Default",
          value: "default",
          sortable: false,
        },
        {
          text: "Contact Type",
          value: "contactType",
          sortable: false,
        },
        {
          text: "Contact Name",
          value: "contactName",
          sortable: false,
        },
        {
          text: "Contact Title",
          value: "contactTitle",
          sortable: false,
          width: "10%",
        },
        {
          text: "Contact Phone",
          value: "contactPhone",
          sortable: false,
        },
        {
          text: "Contact Fax",
          value: "contactFax",
          sortable: false,
        },
        {
          text: "Contact Email Address",
          value: "contactEmailAddress",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ],
      footerProps: {
        showFirstLastPage: true,
        "items-per-page-options": [5, 10, 15, 20],
      },
    };
  },
  computed: {
    __ContactInformations: {
      get() {
        return this.data;
      },
      set(value) {
        this.$emit("update:data", value);
      },
    },
    contactInformations() {
      return this.data.map((item) => {
        const {
          id,
          archived,
          contactType,
          contactEmail,
          isDefault,
          mainFax,
          mainPhone,
          nameTitle,
        } = item;
        
        return {
          id,
          default: isDefault ? "Yes" : "No",
          contactType: contactType?.label ? contactType.label : "--",
          contactName: this.displayFullName(item),
          contactTitle: nameTitle || "--",
          contactPhone: mainPhone ?  `(${mainPhone.areaCode}) ${mainPhone.phone}` || "--" : "--",
          contactFax: mainFax.areaCode && mainFax.phone ?  `(${mainFax.areaCode}) ${mainFax.phone}` || "--" : "--",
          contactEmailAddress: contactEmail ? contactEmail.email || "--" : "--",
          archived: archived || false,
          status: archived ? "Deactivated" : "Active",
          itemData: item,
        };
      });
    },
  },
  methods: {
    async getEmailTypes() {
      try {
        if (!this.emailTypes.length) {
          this.emailTypes = [
            { label: "Fetching email types...", disabled: true },
          ];

          const result = await this.actions.getEmailTypes();

          if (result) {
            this.emailTypes = result.map((item) => {
              return {
                id: item.id,
                label: item.label,
              };
            });
          } else {
            this.showNotifyMessage({
              type: "danger",
              message: "Unable to fetch data.",
            });
          }
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Unable to fetch data.",
        });
      }
    },
    setDefaultContactInformation() {
      this.defaultContact = this.__ContactInformations.find(
        ({ isDefault }) => isDefault === true
      )?.uuid;
    },
    addContactInformation(contactInfo) {
      let { isDefault, mainPhone, mainFax, contactEmail } = contactInfo;

      contactInfo.mainPhone.areaCode = this.sanitizePhoneString(
        mainPhone.areaCode
      );
      contactInfo.mainFax.areaCode = this.sanitizePhoneString(mainFax.areaCode);

      contactInfo.contactEmail["uuid"] = uuid.v1();

      if (isDefault) {
        this.__ContactInformations.map((info) => {
          info.isDefault = false;
          return info;
        });
      }

      if (!this.selectedContact) {
        this.__ContactInformations.push({
          ...contactInfo,
          id: null,
          uuid: uuid.v1(),
        });
      } else {
        this.__ContactInformations = this.__ContactInformations.map((info) => {
          if (info.uuid == contactInfo.uuid) {
            return {
              ...contactInfo,
            };
          }
          return info;
        });

        this.$nextTick(() => {
          const displayObj = {
            email: contactEmail.email,
            fax: `(${mainFax.areaCode}) ${mainFax.phone}`
          };
          this.$eventBus.$emit("setPreferredMethod", { uuid: contactInfo.uuid, displayObj });
        });
      }

      this.setDefaultContactInformation();
      this.setModalVisibility(false);
    },
    deleteContactInformation(uuid) {
      this.__ContactInformations.map((item) => {
        item.isDefault = false;

        return item;
      });

      const findIndex = this.__ContactInformations.findIndex(
        (item) => item.uuid === uuid
      );

      if (uuid === this.defaultContact) {
        if (findIndex > -1) this.__ContactInformations.splice(findIndex, 1);

        if (this.__ContactInformations[0]) {
          this.defaultContact = this.__ContactInformations[0].uuid;
          this.__ContactInformations[0].isDefault = true;
        }
      } else {
        if (findIndex > -1) this.__ContactInformations.splice(findIndex, 1);

        const defaultIndex = this.__ContactInformations.findIndex(
          (item) => item.uuid === this.defaultContact
        );

        if (defaultIndex > -1) {
          this.defaultContact = this.__ContactInformations[defaultIndex].uuid;
          this.__ContactInformations[defaultIndex].isDefault = true;
        }
      }

      this.$eventBus.$emit("removePreferredMethod", uuid);
    },
    selectDefault(uuid) {
      this.__ContactInformations.map((item) => {
        item.isDefault = false;

        return item;
      });

      const findIndex = this.__ContactInformations.findIndex(
        (item) => item.uuid === uuid
      );

      if (findIndex > -1)
        this.__ContactInformations[findIndex].isDefault = true;
    },
    setModalVisibility(visible = false) {
      this.contactModalVisibility = visible;
      if (!visible) this.selectedContact = null;
    },
    editContactInfo(contactInformation) {
      this.selectedContact = contactInformation;
      this.setModalVisibility(true);
    },
    async saveContactInfo() {
      this.setModalVisibility(false);
      this.showNotifyMessage({
        type: "success",
        message: "Client Contact saved.",
      });
      await this.getContactInformations();

      if(this.editMode){
        this.$eventBus.$emit("refreshClientContractualInfo");
      }
    },
    async archiveContactInformation(contactInformationId) {
      try {
        await this.actions.archiveContactInformation({
          id: {
            type: "UUID!",
            value: contactInformationId,
          },
        });

        this.showNotifyMessage({
          type: "success",
          message: "Client Contact set as deactivated.",
        });
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Problem has occurred while saving data.",
        });
      } finally {
        this.promptVisibility = false;
        this.getContactInformations();
      }
    },
    async deArchiveContactInformation(contactInformationId) {
      try {
        await this.actions.deArchiveContactInformation({
          id: {
            type: "UUID!",
            value: contactInformationId,
          },
        });

        this.showNotifyMessage({
          type: "success",
          message: "Client Contact activated.",
        });
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Problem has occurred while saving data.",
        });
      } finally {
        this.promptVisibility = false;
        this.getContactInformations();
      }
    },
    setPromptModalVisibility({ action, id, message }) {
      this.promptVisibility = true;
      this.promptMessage = message;
      this.promptData = {
        action,
        id,
      };
    },
    async setPromptAction(data) {
      const { action, id } = data;
      await this[action](id);
    },
    async getContactInformations() {
      try {
        this.isLoading = true;

        const result = await this.actions.getPayorAccount({
          variables: {
            property: {
              id: {
                type: "UUID!",
                value: this.clientId,
              },
            },
          },
          limitData: [
            this.buildSubQuery("defaultContactInformation", []),
            this.buildSubQuery("contactInformations(archive: true)", [
              this.buildSubQuery("contactType", ["label"]),
              "firstName",
              "middleName",
              "lastName",
              "nameTitle",
              this.buildSubQuery("mainPhone", ["phone", "areaCode"]),
              this.buildSubQuery("mainFax", ["phone", "areaCode"]),
              this.buildSubQuery("contactEmail", [
                "email",
                this.buildSubQuery("emailType", []),
              ]),
              "archived",
            ]),
          ],
        });

        if (result) this.populateContactInformations(result);
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Unable to fetch data.",
        });
      } finally {
        this.isLoading = false;
      }
    },
    populateContactInformations(data) {
      if (data.contactInformations && data.contactInformations.length)
        this.__ContactInformations = data.contactInformations.map((item) => {
          let isDefault = false;

          if (data.defaultContactInformation) {
            if (item.id === data.defaultContactInformation.id) isDefault = true;
          }

          const {
            id,
            contactType,
            firstName,
            middleName,
            lastName,
            nameTitle,
            mainPhone,
            mainFax,
            contactEmail,
            archived,
          } = item;

          return {
            id,
            isDefault,
            contactType: {
              id: contactType ? contactType.id : null,
              label: contactType ? contactType.label : "--",
            },
            firstName: firstName || "--",
            middleName: middleName || "--",
            lastName: lastName || "--",
            nameTitle: nameTitle || "--",
            mainPhone: mainPhone
              ? {
                  id: mainPhone.id,
                  phone: mainPhone.phone,
                  areaCode: mainPhone.areaCode,
                }
              : {
                  id: null,
                  phone: "",
                  areaCode: "",
                },
            mainFax: mainFax
              ? {
                  id: mainFax.id,
                  phone: mainFax.phone,
                  areaCode: mainFax.areaCode,
                }
              : {
                  id: null,
                  phone: "",
                  areaCode: "",
                },
            contactEmail: contactEmail
              ? {
                  id: contactEmail.id,
                  email: contactEmail.email,
                  emailTypeId: contactEmail.emailType
                    ? contactEmail.emailType.id
                    : null,
                }
              : {
                  id: null,
                  email: "",
                  emailTypeId: null,
                },
            archived,
          };
        });
    },
    displayFullName(contact) {
      return `${contact.lastName}, ${contact.firstName} ${
        contact.middleName || ""
      }`;
    },
  },
  created() {
    if (this.editMode) {
      this.columns.splice(this.columns.length - 1, 0, {
        text: "Status",
        align: "center",
        value: "status",
        sortable: false,
      });
    }
  },
};
